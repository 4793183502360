import React, { useState } from 'react';
import { IPatient, IPersonalData, ReduxStore } from '../../../constants/types';
import { Bill, BillItem, DocumentStatus, DocumentType } from './types';
import { Translate } from 'react-localize-redux';
import { BillForm } from './BillForm';
import { FindPatient } from './find_patient';
import { useStatusDocument } from '../../../hooks';
import { useSelector } from 'react-redux';
import PatientInfo from './PatientInfo';
import { Box, Card, FormControl, FormControlLabel, FormLabel, Grid, Radio, 
        RadioGroup, Typography } from '@mui/material';
import { BillableComboSelector } from '../../../components/BillableComboSelector';

interface BillCreateProps {
    patients:IPatient[],
    personalFields:IPersonalData[],
    currency: string,
    uuidInvestigation: string,
    idBillingInfo:number,
    canCreateBugdet: boolean,
    languageCode: string,
    surveyAdditionalInfo?: any,
    withDiscount: boolean,
    onCreateBill: (bill: Bill) => void,
    onCancelBill: () => void

}

const BillCreate: React.FC<BillCreateProps> = (props) => {
    const [patient, setPatient] = useState<null | IPatient>(null);
    const [typeDocument, setTypeDocument] = useState<DocumentType>(props.canCreateBugdet ? DocumentType.SUMMARY : DocumentType.INVOICE);
    const {statusDocument, renderStatusDocument} = useStatusDocument(DocumentStatus.DRAFT);

    function onCreateBill(billItems: BillItem[]) {
        console.log("onCreateBill BillCreate", billItems);
        const bill: Bill = {} as Bill;
        bill.type = typeDocument;
        bill.status = statusDocument;
        bill.billItems = billItems;
        bill.uuidPatient = patient!.uuid;
        props.onCreateBill(bill);
    }

    function onPatientSelected(idPatient: number) {
        const findPatient = props.patients.find((patient) => patient.id === idPatient);
        if (findPatient) {
            setPatient(findPatient);
        }
        console.log(findPatient);
    }

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypeDocument(Number((event.target as HTMLInputElement).value));
    };
    
    function renderTypeDocument(){
        if(props.canCreateBugdet && patient){
            return (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label"><Typography variant="body2" component='span' fontWeight='bold' ><Translate id="hospital.billing.bill.type" /></Typography></FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={typeDocument}
                    onChange={handleChangeType}
                  >
                    <FormControlLabel value={DocumentType.SUMMARY} control={<Radio />} label={<Typography variant="body2" component='span' ><Translate id="hospital.billing.bill.types.summary" /></Typography>} />
                    <FormControlLabel value={DocumentType.INVOICE} control={<Radio />} label={<Typography variant="body2" component='span' ><Translate id="hospital.billing.bill.types.invoice" /></Typography>} />
                  </RadioGroup>
                </FormControl>
              );
        }
        else{
            return null;
        }
    }

    function renderOptions(){
        if(patient){
            return (
                <Card style={{margin:'10px'}}>
                    <Box padding={2} > 
                        { renderTypeDocument() }
                        { renderStatusDocument() }
                    </Box>
                </Card>
            )
        }
        else{
            return null;
        }
        
    }

    function renderPatient(){
        if(!patient){
            return <FindPatient uuidInvestigation={props.uuidInvestigation}
                        personalFields={props.personalFields}
                        codeLanguage={props.languageCode}
                        onPatientSelected={(idPatient) => onPatientSelected(idPatient)} />
        }
        else{
            return (
                <Card style={{margin:'10px'}}>
                    <Box padding={2} > 
                    <PatientInfo patient={patient} languageCode={props.languageCode} rightSide={<BillableComboSelector />} />
                    </Box>
                </Card>
            )
        }
    }

    return (
        <>
            {
                renderPatient()
            }
            {
                renderOptions()
            }
            
            <BillForm patient={patient!} canUpdateBill={true} currency={props.currency} idBillingInfo={props.idBillingInfo}
                withDiscount={props.withDiscount} uuidInvestigation={props.uuidInvestigation} bill={null} 
                print={false}
                onBillItemsValidated={onCreateBill} 
                onUpdateBillItemStatus={(billItems) => console.log("Bill items status updated:", billItems)}
                onCancelBill={props.onCancelBill}
                />
        </>
    );
};

export default BillCreate;
