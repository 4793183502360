import React from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  useTheme,
  Box,
  Grid,
  Typography,
  Divider
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const StatsContainer = styled(Box)`
  display: flex;
  gap: ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(2)};
  justify-content: space-around;
  align-items: center;
`;

const StatBox = styled(Box)`
  text-align: center;
`;

const AppointmentStatsChart = ({ 
  stats, 
  title = "Department Statistics",
  height = 350 
}) => {
  const theme = useTheme();

  // Early return if no stats
  if (!stats) return null;

  const departmentData = Object.entries(stats.departmentStats || {}).map(([id, data]) => ({
    id,
    ...data
  }));

  const chartOptions = {
    chart: {
      type: 'bar',
      height: height,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 2,
        distributed: true
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return value + '%';
      },
      style: {
        fontSize: '12px',
      }
    },
    xaxis: {
      categories: departmentData.map(dept => dept.name),
      labels: {
        style: {
          colors: theme.palette.text.secondary,
          fontSize: '12px'
        },
        rotate: -45,
        trim: true
      }
    },
    yaxis: {
      title: {
        text: 'Percentage (%)',
        style: {
          color: theme.palette.text.primary
        }
      },
      labels: {
        formatter: (value) => value.toFixed(1) + '%'
      }
    },
    colors: [
      theme.palette.primary.light,
      theme.palette.primary.main,
      theme.palette.primary.dark,
      theme.palette.secondary.light,
      theme.palette.secondary.main,
      theme.palette.secondary.dark,
    ],
    legend: {
      show: false
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return value.toFixed(1) + '%';
        }
      }
    }
  };

  const series = [{
    name: 'Percentage',
    data: departmentData.map(dept => parseFloat(dept.percentage))
  }];

  return (
    <Card mb={6}>
      <CardHeader title={title} />
      <Divider />
      <StatsContainer>
        <StatBox>
          <Typography variant="h3" color="primary.main">
            {stats.totalAppointments}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Total Appointments
          </Typography>
        </StatBox>
        <StatBox>
          <Typography variant="h3" color="error.main">
            {stats.totalOverdue}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Total Overdue
          </Typography>
        </StatBox>
      </StatsContainer>
      <CardContent>
        <Box height={height} width="100%">
          <Chart
            options={chartOptions}
            series={series}
            type="bar"
            height="100%"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AppointmentStatsChart;