// analytics/hooks/useRadiologyData.ts
import { useState, useEffect } from 'react';

import { radiologyServices } from '../services/endpoints/radiology';

interface RadiologyData {
  totalScans: any;
  turnaroundTime: any;
  patientCount: any;
  monthlyTrends: any;
  location: any;
}

export const radiologyMethodMap = {
  totalScans: radiologyServices.getTotalScans,
  turnaroundTime: radiologyServices.getTurnaroundTime,
  patientCount: radiologyServices.getPatientCount,
  monthlyTrends: radiologyServices.getMonthlyTrends,
  location: radiologyServices.getLocation,
};

interface UseRadiologyDataOptions {
  investigation: { uuid: string };
  departmentSelected?: string;
  startDate: string;
  endDate: string;
  requestedMethod: keyof typeof radiologyMethodMap;
}

export const useRadiologyData = ({
  investigation,
  departmentSelected,
  startDate,
  endDate,
  requestedMethod,
}: UseRadiologyDataOptions) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const uuidDepartment = departmentSelected || 'all';

    if (!investigation?.uuid || !startDate || !endDate) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const fetchMethod = radiologyMethodMap[requestedMethod];
        if (!fetchMethod) {
          throw new Error(`Unknown method: ${requestedMethod}`);
        }

        const response = await fetchMethod(
          investigation.uuid,
          uuidDepartment,
          startDate,
          endDate
        );
        setData(response);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [investigation, departmentSelected, startDate, endDate, requestedMethod]);

  return { data, loading, error };
};


