// analytics/hooks/useOutpatientsData.ts
import { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';  // Import useSelector instead of connect
import { 
  getStatsOutpatients, 
  getOutpatientsByDepartment, 
  getDiagnosisWithAppointments,
  outpatientServices 
} from '../services/endpoints/outpatients';
import { OutPatientResponse, DiagnosisResponse, ActivityPatientsDistributionResponse, ChartData, DistributionResponse, AppointmentDepartmentResponse, NewPatientStats } from '../types';
import { IPersonalData, ReduxStore, IPatient, IDepartment } from '../../../../constants/types';
import { transformAppointmentsData, transformUniquePatientData, convertAppointmentTypeToChartData, convertNewPatientStatsToChartData } from '../services/transformers';
import { updatePatientsFromId } from '../../../../redux/actions/patientsActions';

interface IAppointmentByType {
  id: string;
  name: string;
  count: number;
}

export interface OutpatientsData {
  stats: OutPatientResponse;
  byDepartment: OutPatientResponse;
  diagnosis: ChartData;
  appointmentDistribution: DistributionResponse;
  totalPatients: ChartData,
  newPatients: NewPatientStats;
  patientsDetails: IPersonalData[];
  appointmentsByType: IAppointmentByType[];
  overdueAppointments: AppointmentDepartmentResponse;
  appointmentsByDoctor: OutPatientResponse;
  appointementStats: OutPatientResponse;
  appointmentTypeData: any
  activityDistribution: ActivityPatientsDistributionResponse['stats'];
}


export const useOutpatientsData = (
  investigation: { uuid: string },
  departmentSelected: string | undefined,
  startDate: string,
  endDate: string
) => {
  const [data, setData] = useState<OutpatientsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Get patients data from Redux store using useSelector
  const investigationsData = useSelector((state: ReduxStore) => {
    const investigations = state.hospital.data.departments;
    if (!investigations) return [];
    return investigations as IDepartment[];
  }, shallowEqual); // Add shallowEqual from react-redux

  const patientsData = useSelector((state: ReduxStore) => {
    const currentInvestigation = state.investigations.currentInvestigation;
    if (!currentInvestigation) return [];
    return state.patients.data?.[currentInvestigation.uuid] as IPatient[] || [];
  }, shallowEqual);

  const dispatch = useDispatch();


  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      if (!patientsData.length) {
        dispatch(updatePatientsFromId(investigation, 0));
      }

      const uuidDepartment = departmentSelected || 'all';
      
      if (!investigation?.uuid || !startDate || !endDate) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const [
          statsResponse,
          departmentResponse,
          diagnosisResponse,
          appointmentsResponse,
          totalPatientsResponse,
          newPatientsResponse,
          overdueAppointmentsResponse,
          appointmentsByDoctorResponse,
          appointmentStatsResponse,
          appointmentTypeDataResponse,
          activityDistributionResponse
        ] = await Promise.all([
          getStatsOutpatients(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          getOutpatientsByDepartment(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          getDiagnosisWithAppointments(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getAppointments(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getTotalPatients(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getNewPatientsRegistration(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getOverdueAppointments(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getAppointmentsByDoctor(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getAppointmentStats(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getAppointmentType(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
          outpatientServices.getActivity(investigation.uuid, uuidDepartment, startDate, endDate, abortController.signal),
        ]);

        // Filter patients based on diagnosisResponse.patients
        const relevantPatients = patientsData.filter(
          patient => diagnosisResponse.patients.includes(patient.id)
        ).map(patient => patient.personalData);

        const relevantDepartments = Object.keys(appointmentsResponse.stats).map(stat =>{ 
          const name = investigationsData.find(investigation => investigation.uuid === stat)?.name || 'no name';
          return {
            id: stat,
            name: name,
            count: appointmentsResponse.stats[stat]
          }
        });

        const appointmentTypeData = convertAppointmentTypeToChartData(relevantDepartments);

        const diagnosisChartData = transformUniquePatientData(diagnosisResponse);
        const patientResponseProps: ChartData = totalPatientsResponse && totalPatientsResponse.props ? totalPatientsResponse.props : {};

        const newPatientsStats = convertNewPatientStatsToChartData(newPatientsResponse.stats);
        


        setData({
          stats: statsResponse,
          byDepartment: departmentResponse,
          diagnosis: diagnosisChartData,
          appointmentDistribution: appointmentsResponse.chartData,
          totalPatients: patientResponseProps,
          newPatients: newPatientsStats,
          patientsDetails: relevantPatients,
          overdueAppointments: overdueAppointmentsResponse,
          appointmentsByType: appointmentTypeData,
          appointmentsByDoctor: appointmentsByDoctorResponse,
          appointementStats: appointmentStatsResponse,
          appointmentTypeData: appointmentTypeDataResponse,
          activityDistribution:activityDistributionResponse.stats 
        });

      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err instanceof Error ? err : new Error('Unknown error'));
        }

      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => abortController.abort();
  }, [investigation, departmentSelected, startDate, endDate, patientsData]);

  return { data, loading, error };
};