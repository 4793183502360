import { useState, useEffect } from 'react';
import { financesServices } from '../services/endpoints/finaces';

interface FinancesData {
  totalBilling: any;
  totalDiscount: any;
  totalIncome: any;
  billingByLocation: any;
  billingByInsurance: any;
  billingByDepartment: any;
  monthlyBillingTrends: any;
  outpatientBillingTrends: any;
  inpatientBillingTrends: any;
  laboratoryBillingTrends: any;
  radiologyBilling: any;
}

export const financesMethodMap = {
  totalBilling: financesServices.getTotalBilling,
  totalDiscount: financesServices.getTotalDiscount,
  totalIncome: financesServices.getTotalIncome,
  billingByLocation: financesServices.getBillingByLocation,
  billingByInsurance: financesServices.getBillingByInsurance,
  billingByDepartment: financesServices.getBillingByDepartment,
  monthlyBillingTrends: financesServices.getMonthlyBillingTrends,
  outpatientBillingTrends: financesServices.getOutpatientBillingTrends,
  inpatientBillingTrends: financesServices.getInpatientBillingTrends,
  laboratoryBillingTrends: financesServices.getLaboratoryBillingTrends,
  radiologyBilling: financesServices.getRadiologyBilling,
};

interface UseFinancesDataOptions {
  investigation: { uuid: string };
  departmentSelected?: string;
  startDate: string;
  endDate: string;
  requestedMethod: keyof typeof financesMethodMap;
}

export const useFinancesData = ({
  investigation,
  departmentSelected,
  startDate,
  endDate,
  requestedMethod,
}: UseFinancesDataOptions) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const uuidDepartment = departmentSelected || 'all';

    if (!investigation?.uuid || !startDate || !endDate) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const fetchMethod = financesMethodMap[requestedMethod];
        if (!fetchMethod) {
          throw new Error(`Unknown method: ${requestedMethod}`);
        }

        const response = await fetchMethod(
          investigation.uuid,
          uuidDepartment,
          startDate,
          endDate
        );
        setData(response);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
        console.error('Error fetching finances data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [investigation, departmentSelected, startDate, endDate, requestedMethod]);

  return { data, loading, error };
};
