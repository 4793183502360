interface IAppointmentByType {
  id: string;
  name: string;
  count: number;
}

interface IChartProps {
  series: { name: string, data: number[] }[];
  categories: string[];
  count?: number;
}

interface NewPatientStats {
  [id: string]: {
    name: string;
    newPatients: number;
  };
}

interface DischargesWardsType {
  [key: string]: {
    name: string;
    discharges: number;
  };
}
export interface DischargesStats {
  [id: string]: {
    name: string;
    totalDischarges: number;
    wards: DischargesWardsType;
  }
}

export function convertAppointmentTypeToChartData(appointments: IAppointmentByType[]): IChartProps {
  // Extract categories (names)
  const categories = appointments.map(apt => apt.name);
  
  // Create single series for appointment counts
  const series = [{
    name: 'Appointments',
    data: appointments.map(apt => apt.count)
  }];

  // Calculate total count
  const totalCount = appointments.reduce((sum, apt) => sum + apt.count, 0);

  return {
    series,
    categories,
    count: totalCount
  };
}

export function convertNewPatientStatsToChartData(stats: NewPatientStats): IChartProps {
	// Extract non-null departments and their data
	const departments = Object.values(stats)
			.filter((dept): dept is { name: string; newPatients: number } => 
					dept !== null && dept.name !== null);

	// Create categories from department names
	const categories = departments.map(dept => dept.name);

	// Create series with new patient counts
	const data = departments.map(dept => dept.newPatients);
	const series = [{
			name: 'New Patients',
			data
	}];

	// Calculate total count
	const count = departments.reduce((sum, dept) => sum + dept.newPatients, 0);

	return {
			series,
			categories,
			count
	};
}

export function convertDischargeStatsToChartData(stats: DischargesStats): ChartData {
  // Get all unique ward names for categories
  const wardNames = new Set<string>();
  const departmentWardDischarges = new Map<string, Map<string, number>>();
  
  // Process data
  Object.values(stats).forEach(dept => {
      departmentWardDischarges.set(dept.name, new Map());
      Object.values(dept.wards).forEach(ward => {
          wardNames.add(ward.name);
          departmentWardDischarges.get(dept.name).set(ward.name, ward.discharges);
      });
  });

  const categories = Array.from(wardNames)
      .filter((name): name is string => name !== null);

  // Create series by department
  const series: ChartSeries[] = Array.from(departmentWardDischarges.entries())
      .map(([deptName, wardMap]) => ({
          name: deptName,
          data: categories.map(wardName => wardMap.get(wardName) || 0)
      }));

  // Calculate total count
  const count = Object.values(stats).reduce((deptSum, dept) => 
      deptSum + Object.values(dept.wards).reduce((wardSum, ward) => 
          wardSum + ward.discharges, 0), 0);

  return {
      series,
      categories,
      count
  };
}