// AnalyticsHeader.tsx
import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box, Container, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { analyticsTabs, analyticsConfig } from './config/analyticsConfig';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: 3
  },
}));

const StyledTab = styled(Tab)(({ theme, color }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.7)',
  '&.Mui-selected': {
    color: color || analyticsConfig.activeColor,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `3px solid ${color || analyticsConfig.activeColor}`,
  },
  '&:hover': {
    color: color || analyticsConfig.defaultColor,
    opacity: 1,
  },
}));

const HeaderContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderBottom: '1px solid #e8e8e8',
  marginBottom: theme.spacing(3)
}));

const TitleSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 0),
  backgroundColor: theme.palette.background.paper,
}));

function AnalyticsHeader({ colors = [], onTabChange }) {
  const [value, setValue] = useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <HeaderContainer>
      <Container maxWidth="xl" sx={{ marginLeft: 0 }}>
        <TitleSection>
          <Typography variant="h3" component="h1" gutterBottom sx={{ color: analyticsTabs[value].color }}>
            {analyticsTabs[value].label}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {analyticsConfig.subtitle}
          </Typography>
        </TitleSection>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: { display: 'none' }
          }}
        >
          {analyticsTabs.map((tab, index) => (
            <StyledTab 
              key={tab.id}
              label={tab.label}
              color={tab.color || analyticsConfig.defaultColor}
            />
          ))}
        </StyledTabs>
      </Container>
    </HeaderContainer>
  );
}

export default AnalyticsHeader;