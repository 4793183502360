import { useState, useEffect } from 'react';
import axios from '../utils/axios';
import { IRequest, RequestType } from '../pages/hospital/Request/types';

interface UseRequestsProps {
    requestServiceType: number;
    uuidPatient?: string;
    uuidInvestigation: string;
    typeRequest: RequestType;
}

interface UseRequestsReturn {
    requests: IRequest[] | null;
    loading: boolean;
    error: Error | null;
}

export const useRequests = ({
    requestServiceType,
    uuidPatient,
    uuidInvestigation,
    typeRequest
}: UseRequestsProps): UseRequestsReturn => {
    const [requests, setRequests] = useState<IRequest[] | null>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setError(null);

            try {
                const url = uuidPatient 
                    ? `${import.meta.env.VITE_APP_API_URL}/hospital/${uuidInvestigation}/requests/${uuidPatient}/${requestServiceType}`
                    : typeRequest === RequestType.SERVICES 
                        ? `${import.meta.env.VITE_APP_API_URL}/hospital/${uuidInvestigation}/requests/${requestServiceType}` 
                        : `${import.meta.env.VITE_APP_API_URL}/hospital/${uuidInvestigation}/requests_pharmacy`;

                const response = await axios(url, { 
                    headers: {"Authorization" : localStorage.getItem("jwt")} 
                });
                
                if (response.status === 200 && response.data) {
                    setRequests(response.data.requests);
                }
            } catch (err) {
                const error = err instanceof Error ? err : new Error('Failed to fetch requests');
                setError(error);
                console.error('Error fetching requests:', err);
            } finally {
                setLoading(false);
            }
        }
        fetchData();    
    }, [requestServiceType, uuidPatient, uuidInvestigation, typeRequest]);

    return { requests, loading, error };
};