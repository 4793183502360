import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete } from "@mui/lab";
import { Grid, TextField } from '@mui/material';
import { ButtonAdd } from './general/mini_components';
import { ReduxStore } from '../constants/types';
import { BillableCombo } from '../pages/hospital/Billing/types';
import { pushBillables } from '../redux/actions/billingActions';

export const BillableComboSelector: React.FC = () => {
    const [comboSelected, setComboSelected] = useState<BillableCombo | null>(null);
    const dispatch = useDispatch();
    
    const billableCombos = useSelector((state:ReduxStore) => 
        state.billing.data.billableCombos ? state.billing.data.billableCombos : []
    );
    const loadingBillables = useSelector((state:ReduxStore) => state.billing.loading);

    const addBillablesCombo = async () => {
        if(comboSelected){
            dispatch(pushBillables(comboSelected.billables));
            setComboSelected(null);
        }
    };

    const selectCombo = (selectedCombo: BillableCombo | null) => {
        if(selectedCombo === null) return;
        
        const comboFound = billableCombos.find((combo) => combo.id === selectedCombo.id);
        if(comboFound){
            setComboSelected(selectedCombo);
        }
    };

    if (billableCombos.length === 0) return null;

    return (
        <Grid container item xs={6} style={{ display:'flex', paddingTop: '1rem' }}>
            <Autocomplete
                disabled={loadingBillables}
                value={comboSelected}
                options={billableCombos}
                onChange={(_, newValue) => selectCombo(newValue)}
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        label="Select combo" 
                        error={false}
                        helperText={null} 
                        color="secondary"
                        variant="outlined" 
                    />
                }
            />
            {comboSelected && (
                <ButtonAdd style={{marginTop:'0.5rem'}} onClick={addBillablesCombo} />
            )}
        </Grid>
    );
}; 