import React from "react";
import { Card, CardHeader, CardContent, Box } from "@mui/material";
import Chart from "react-apexcharts";

interface ChartProps {
  id: string;
  name: string;
  count: number;
}

interface PlaceholderChartProps {
  title: string;
  type: string;
  height: number;
  data: ChartProps[];
}

const PlaceholderChart: React.FC<PlaceholderChartProps> = ({
  title,
  type,
  height = 300,
  data,
}) => {
  const chartOptions = {
    chart: {
      type: "donut",
    },
    labels: data.map((item) => item.name),
    colors: ["#00BCD4", "#FF9800", "#4CAF50", "#E91E63", "#9C27B0"],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const chartSeries = data.map((item) => item.count);

  return (
    <Card>
      <CardHeader title={title} subheader={`Chart Type: ${type}`} />
      <CardContent>
        <Box height={height}>
          {data ? (
            <Chart options={chartOptions} series={chartSeries} type="donut" height={height} />
          ) : (
            <Box>Loading chart data...</Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PlaceholderChart;
