// analytics/components/charts/ChartFactory.tsx
import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import BarChart from './BarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import DoughnutChart from './DoughnutChart';
import GaugeChart from './GaugeChart';
import PlaceholderChart from '../PlaceholderChart';
import { OutPatientResponse, ChartData , DistributionResponse } from '../types';
import DemographicChart from './DemographicsCharts';
import DataValue from './DataValue';
// import PatientBarChart from '../../../dashboards/Analytics/PatientsBarChart';
import { IDepartment } from '../../../../constants/types';
import PatientBarChart from './PatientsBarChart';
import MultiChart from './MultiChart';
import ProductivityTable from './ProductivityTable';
import AppointmentStatsChart from './AppointmentStatsChart';
import { C } from 'vitest/dist/reporters-yx5ZTtEV';

export interface ChartFactoryProps {
  type: 'bar' | 'line' | 'pie' | 'doughnut' | 'gauge' | 'demographic' | 'data-only' | 'data-value' | 'patient-bar' | 'productivity' | 'multi-chart' | 'donut' | 'bar-chart' | 'horizontal-bar' | 'appointment-stats' | 'distribuited';
  title: string;
  data: OutPatientResponse | ChartData | DistributionResponse;
  id: string;
  departments:IDepartment[];
  departmentSelected:IDepartment;
 
}
interface BarChartProps{
  series: {
      name: string,
      count: number[]
  }[],
  title:Element,
  categories:Date[]
}




const ChartFactory: React.FC<ChartFactoryProps> = ({ type, title, data: originalData, id, departments, departmentSelected }) => {

  // If no data, show placeholder
  if (!originalData) {
    console.log('No data in chart factory', title, type, originalData);
    return <PlaceholderChart title={title} type={type} data={title}  />;
  }

  const data = originalData.chartData ? originalData.chartData : originalData;

  const categories = data.query?.map((item) => item.date) || [];
  const seriesDepartment = {
    name: title,
    count: data.trend?.totals || []
  };
  
  console.log('categories', categories, seriesDepartment);

  const renderChart = () => {
    switch (type) {
      case 'bar':
        return <BarChart data={data} title={title} categories={categories} series={seriesDepartment} type={type} />;
      case 'demographic':
        return <DemographicChart data={data} title={title} />;
      case 'data-value':
        return <DataValue data={data} title={title} type={type} value={'hopla'} size={'large'}/>;
      case 'multi-chart':
        return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={true} type="bar"/>;
      case 'patient-bar':
        return <PatientBarChart departments={departments} departmentSelected={departmentSelected} data={data} statsPerDepartment={data} title={title}  />;
      case 'bar-chart':
          return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={false} type="bar" total={data.count} stacked={false}/>;
      case 'distribuited':
        return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={false} type="bar" total={data.count} stacked={true} ditributed={true}/>;
      case 'horizontal-bar':
        return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={true} type="bar" total={data.count}/>;

      case 'line':
        return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={false} type="line" total={data.count}/>;
      case 'pie':
        return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={false} type="pie" total={data.count}/>;
      case 'donut':
          return <MultiChart series={data.series} categories={data.categories} title={title} horizontal={false} type="donut" total={data.count}/>;
      case 'appointment-stats':
            return <AppointmentStatsChart stats={data.stats} title={title} height={400}/>;
      case 'doughnut':
        return <DoughnutChart data={data} title={title} type={type} />;
      case 'gauge':
        return <GaugeChart data={data} title={title} type={type} />;
      case 'data-only':
        return <PlaceholderChart title={title} type={type} data={originalData}/>;
      case 'productivity':
        return <ProductivityTable data={data.data} title={title} type={type} stats={data.stats} department={data.department}/>;
      default:
        return <PlaceholderChart title={title} type={type} data={data}/>;
    }
  };

  return (
    <Card>
      {/* <CardHeader title={title} /> */}
      <CardContent>
        {renderChart()}
      </CardContent>
    </Card>
  );
};

export default ChartFactory;