import React from "react";
import styled from "styled-components";
import Chart from 'react-apexcharts';
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    CardHeader,
    useTheme,
    Box
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

interface BarChartProps {
    series: {
        name: string,
        data: number[]
    }[],
    title: Element,
    categories: string[],
    type?: 'bar' | 'line' | 'area' | 'radar' | 'scatter' | 'heatmap' | 'pie' | 'donut',
    stacked?: boolean,
    horizontal?: boolean
    total?: number,
    ditributed?: boolean
}

const EnhancedChart = ({ 
    series, 
    title, 
    categories, 
    type = 'bar',
    stacked = false,
    horizontal = false,
    total,
    ditributed = false
}: BarChartProps) => {
    const theme = useTheme();

    // Function to sum data and get labels for pie/donut
    const getSummedSeriesDataForPie = (series: { name: string, data: number[] }[]) => {
        const values = series.map((s) => s.data.reduce((a, b) => a + b, 0));
        const labels = series.map((s) => s.name);
        return { values, labels };
    };

    const isPieOrDonut = ['pie', 'donut'].includes(type);

    const chartOptions = {
        chart: {
            background: 'transparent',
            stacked: stacked,
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                }
            },
            zoom: {
                enabled: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: horizontal,
                columnWidth: '80%',
                borderRadius: 4,
                distributed: ditributed,
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                reversePositiveShade: true,
            },
        },
        colors: [
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.success.main,
            theme.palette.warning.main,
            theme.palette.error.main,
            '#9C27B0',
            '#795548',
            '#607D8B'
        ],
        dataLabels: {
            enabled: true
        },
        fill: {
            type: type === 'area' ? 'gradient' : 'solid',
            opacity: type === 'area' ? [0.35, 0.25, 0.2] : 1,
        },
        grid: {
            show: true,
            borderColor: theme.palette.divider,
            strokeDashArray: 3,
            position: 'back',
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            floating: false,
            offsetY: 0,
            offsetX: 0
        },
        stroke: {
            curve: 'straight',
            width: type === 'bar' ? 0 : 2,
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            shared: true,
            intersect: false,
            theme: theme.palette.mode,
        },
        xaxis: {
            type: 'category',
            categories: categories,
            axisBorder: {
                show: true,
                color: theme.palette.divider,
            },
            axisTicks: {
                show: true,
                color: theme.palette.divider,
            },
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                },
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: theme.palette.text.secondary,
                }
            }
        },
        // Special settings for pie/donut charts
        ...(isPieOrDonut && {
            labels: series.map((s) => s.name), // Use series names as labels
            pie: {
                expandOnClick: true,
                donut: {
                    size: '70%',
                    labels: {
                        show: true,
                        name: {
                            show: true
                        },
                        value: {
                            show: true
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                            }
                        }
                    }
                }
            }
        })
    };

    // Determine the data to use for the chart
    const seriesData = isPieOrDonut
        ? getSummedSeriesDataForPie(series) // Get summed data for pie/donut charts
        : series?.map(s => ({
            name: s.name,
            data: s.data
        }));

    return (
        <Card>
            <CardHeader 
                title={title} 
                subheader={total ? `Total: ${total}` : null} 
            />
            <CardContent>
                <Box>
                    <Chart
                        options={chartOptions}
                        series={isPieOrDonut ? seriesData.values : series}
                        type={type}
                        height={400}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};


export default EnhancedChart;
