import React, { useMemo } from 'react';
import { Grid, Card, CardHeader, CardContent, Box } from '@mui/material';
import { useInpatientsData } from '../../hooks/useInpatientsData';
import { analyticsCharts } from '../../config/analyticsCharts';
import LoadingState from '../../common/LoadingState';
import ErrorState from '../../common/ErrorState';
import ChartFactory from '../../charts/ChartFactory';
import { IDepartment } from '../../../../../constants/types';

interface ChartConfig {
  id: string;
  source: string;
  order: number;
  title: string;
  type: 'bar' | 'line' | 'pie' | 'doughnut' | 'gauge' | 'demographic' | 'data-only' | 'data-value';
  cols: number;
  visible: boolean;
  debug?: boolean;
}

interface TabProps {
  investigation: {
    permissions: string[];
    [key: string]: any;
  };
  departmentSelected: string;
  startDate: number | null;
  endDate: number | null;
  departments: IDepartment[];
}

const InPatientsTab: React.FC<TabProps> = ({ 
  investigation, 
  departmentSelected, 
  startDate, 
  endDate, 
  departments,
}) => {
  const visibleCharts = useMemo(() => 
    (analyticsCharts.inpatients as ChartConfig[] || [])
      .filter(chart => chart.visible)
      .sort((a, b) => a.order - b.order),
    [analyticsCharts.inpatients]
  );
  
  const debugCharts = useMemo(() => 
    (analyticsCharts.inpatients as ChartConfig[] || [])
      .filter(chart => chart.debug)
      .sort((a, b) => a.order - b.order),
    [analyticsCharts.inpatients]
  );

  const defaultDepartment = useMemo(() => 
    departments && departments.length > 0 ? departments[0] : null,
    [departments]
  );
  
  return (
    <>
      <Grid container spacing={3}>
        {visibleCharts.map((chart) => (
          <Grid 
            item 
            xs={12} 
            sm={chart.cols === 12 ? 12 : 6} 
            md={chart.cols} 
            key={chart.id}
          >
            <ChartWrapper
              chart={chart}
              investigation={investigation}
              departmentSelected={departmentSelected}
              startDate={startDate}
              endDate={endDate}
              departments={departments}
              defaultDepartment={defaultDepartment}
            />
          </Grid>
        ))} 
      </Grid>
      <>
        {debugCharts.map((chart) => (
          <DebugChartWrapper
            key={chart.id}
            chart={chart}
            investigation={investigation}
            departmentSelected={departmentSelected}
            startDate={startDate}
            endDate={endDate}
          />
        ))}
      </>
    </>
  );
};

interface ChartWrapperProps {
  chart: ChartConfig;
  investigation: TabProps['investigation'];
  departmentSelected: string;
  startDate: number | null;
  endDate: number | null;
  departments: IDepartment[];
  defaultDepartment: IDepartment | null;
}

const ChartWrapper: React.FC<ChartWrapperProps> = ({
  chart,
  investigation,
  departmentSelected,
  startDate,
  endDate,
  departments,
  defaultDepartment,
}) => {
  const { data, loading, error } = useInpatientsData({
    investigation,
    departmentSelected,
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
    requestedMethod: chart.source as any
  });

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <ChartFactory
      id={chart.id}
      type={chart.type}
      title={chart.title}
      data={data}
      departments={departments}
      departmentSelected={defaultDepartment}
    />
  );
};

const DebugChartWrapper: React.FC<Omit<ChartWrapperProps, 'departments' | 'defaultDepartment'>> = ({
  chart,
  investigation,
  departmentSelected,
  startDate,
  endDate,
}) => {
  const { data, loading, error } = useInpatientsData({
    investigation,
    departmentSelected,
    startDate: startDate?.toString() || '',
    endDate: endDate?.toString() || '',
    requestedMethod: chart.source as any
  });

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;

  return (
    <Card>
      <CardHeader title={chart.id} subheader={chart.source} />
      <CardContent>
        <Box>
          {data && (
            <pre style={{ 
              overflow: 'auto', 
              maxHeight: '100%',
              margin: 0,
              padding: '1rem',
              background: '#f5f5f5',
              borderRadius: '4px'
            }}>
              {JSON.stringify(data, null, 2)}
            </pre>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default InPatientsTab;