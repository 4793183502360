// analytics/components/common/LoadingState.jsx
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

const LoadingState = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={300} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
        <Skeleton variant="rectangular" width="50%" height={200} />
        <Skeleton variant="rectangular" width="50%" height={200} />
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Skeleton variant="rectangular" width="50%" height={200} />
        <Skeleton variant="rectangular" width="50%" height={200} />
      </Box>
    </Box>
  );
};

export default LoadingState;