// Analytics/services/endpoints/outpatients.js
import axios from 'axios';
import { datalogger } from '../../../../../utils/index.jsx';

const ANALYTICS_BASE_URL = import.meta.env.VITE_APP_API_URL + "/analytics";

const getAuthHeaders = () => ({
    headers: { "Authorization": localStorage.getItem("jwt") }
});

/**
 * Creates full URL for outpatients endpoints
 * @param {string} endpoint - The specific endpoint path (e.g., '/diagnosis')
 * @param {Object} params - Request parameters
 * @returns {string} Complete URL
 */
const buildOutpatientsUrl = (endpoint, { uuidInvestigation, uuidDepartment, startDate, endDate }) => {
    const baseUrl = `${ANALYTICS_BASE_URL}/${uuidInvestigation}/outpatients`;
    const endpointPath = endpoint || ''; // Handle empty endpoint for base stats
    console.log('Building URL:',endpointPath, uuidDepartment, startDate, endDate);
    return `${baseUrl}/${endpointPath}/${uuidDepartment}/startDate/${startDate}/endDate/${endDate}`;
};

const makeOutpatientsRequest = async (params, signal) => {
    const { endpointPath } = params
    const url = buildOutpatientsUrl(endpointPath, params);
    console.log('Making request to:', params, endpointPath); // Helpful for debugging
    
    try {
        const response = await axios.get(url, {
            ...getAuthHeaders(),
            signal
        });
        if (response.status === 200) return response.data;
        console.log('Outpatients API Response:', response.data);
        throw response.data;
    } catch (error) {
        throw error;
    }
};

const createEndpoint = (endpointPath) => {
    console.log('Creating endpoint:', endpointPath);
    return datalogger((uuidInvestigation, uuidDepartment, startDate, endDate, signal) => 
        makeOutpatientsRequest({ 
            endpointPath,
            uuidInvestigation, 
            uuidDepartment, 
            startDate, 
            endDate 
        }, signal)
    );
};

// Grouped services object (new way)
export const outpatientServices = {
    getStats: createEndpoint(''),
    getByDepartment: createEndpoint('department'),
    getDiagnosisWithAppointments: createEndpoint('diagnosis'),
    getAppointments: createEndpoint('appointment-distribution'),
    getTotalPatients: createEndpoint('total-patients'),
    getNewPatientsRegistration: createEndpoint('new-patients-registration'),
    getOverdueAppointments: createEndpoint('overdue-appointments'),
    getAppointmentsByDoctor: createEndpoint('appointments-by-doctor'),
    getAppointmentStats: createEndpoint('appointment-stats'),
    getAppointmentType: createEndpoint('appointment-type'),
    getActivity: createEndpoint('activity'),
};

// Individual exports for backward compatibility (old way)
export const getStatsOutpatients = createEndpoint('patients');
export const getOutpatientsByDepartment = createEndpoint('department');
export const getDiagnosisWithAppointments = createEndpoint('diagnosis');
export const getAppointments = createEndpoint('appointment-distribution');
export const getTotalPatients = createEndpoint('total-patients');
export const getNewPatientsRegistration = createEndpoint('new-patients-registration');
export const getOverdueAppointments = createEndpoint('overdue-appointments');
export const getAppointmentsByDoctor = createEndpoint('appointments-by-doctor');
export const getAppointmentStats = createEndpoint('appointment-stats');
export const getAppointmentType = createEndpoint('appointment-type');
export const getActivity = createEndpoint('activity');

export const OutpatientEndpoints = {
    STATS: '/patients',
    BY_DEPARTMENT: '/department',
    DIAGNOSIS: '/diagnosis',
    APPOINTMENTS: '/appointments',
    TOTAL_PATIENTS: '/total-patients',
    NEW_PATIENTS_REGISTRATION: '/new-patients-registration',
    OVERDUE_APPOINTMENTS: '/overdue-appointments',
    APPOINTMENTS_BY_DOCTOR: '/appointments-by-doctor',
    APPOINTMENT_STATS: '/appointment-stats',
    APPOINTMENT_TYPE: '/appointment-type',
    ACTIVITY: '/activity'
};
