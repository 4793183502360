/**
 * Utility functions for working with surveys
 */

import axios from "axios";
import { Template } from "../components/general/SmartFields/Templated/TemplatedMedicalHistory";
import { TEMPLATES_ENDPOINT, TEMPLATE_BY_ID_ENDPOINT, API_BASE_URL } from "../constants/apiEndpoints";
import { TemplatedMedicalHistoryEditorType } from "../components/general/SmartFields/Templated/TemplatedMedicalHistoryEditor";

// For debugging
console.log("API endpoints:", {
  TEMPLATES_ENDPOINT,
  templateByIdExample: TEMPLATE_BY_ID_ENDPOINT("example-id", "example-uuid")
});

/**
 * Fetches a survey by its ID from the API
 * @param surveyId The ID of the survey to fetch
 * @returns The survey content or an empty string if not found
 */
export async function fetchTemplates(uuidInvestigation: string, type:TemplatedMedicalHistoryEditorType): Promise<Template[]> {
    try {
        console.log("🔍 fetchTemplates called");
        console.log("🔍 Request URL:", TEMPLATES_ENDPOINT(uuidInvestigation, type));
        console.log("🔍 Base API URL:", API_BASE_URL);
        console.log("🔍 Authorization:", localStorage.getItem("jwt"));
        const surveyData = await axios.get(TEMPLATES_ENDPOINT(uuidInvestigation, type), { 
            headers: { "Authorization": localStorage.getItem("jwt") },
        });
        
        console.log("🔍 fetchTemplates response received:", surveyData.status);
        return surveyData.data.templates;
    } catch (error) {
        console.error("Error fetching survey:", error);
        // Log more details about the error for debugging
        console.error("🔍 Full error details:", JSON.stringify(error));
        return [];
    }
}

export async function fetchTemplate(uuidInvestigation: string, templateId: string): Promise<Template | null> {
    try {
        const url = TEMPLATE_BY_ID_ENDPOINT(uuidInvestigation, templateId);
        console.log("🔍 fetchTemplate called for ID:", templateId);
        console.log("🔍 Request URL:", url);
        
        const surveyData = await axios.get(url, { 
            headers: { "Authorization": localStorage.getItem("jwt") },
        });
        
        console.log("🔍 fetchTemplate response received:", surveyData.status);
        return surveyData.data.template;
    } catch (error) {
        console.error("Error fetching template:", error);
        // Log more details about the error for debugging
        console.error("🔍 Full error details:", JSON.stringify(error));
        return null;
    }
}