import React from 'react';
import { Card, CardHeader, CardContent, Box, Skeleton } from '@mui/material';

interface LineChartProps {
  data: {
    trend: {
      data: number[];
      totals: number[];
    };
    stats: {
      name: string;
      totalPatients: number;
    }[];
  };
}

interface PlaceholderChartProps {  
  title: string;
  type: string;
  height: number;
  data: LineChartProps['data'];
}

const PlaceholderChart: React.FC<PlaceholderChartProps> = ({ title, type, height = 300, data}) => (
  console.log('data in placeholder',title, type, height, data),
  <Card>
    <CardHeader title={title} subheader={type} />
    <CardContent>
      <Box height={height}>
        {/* <Skeleton variant="rectangular" height="100%" animation="wave" /> */}
        {data && (
          <pre style={{ 
            overflow: 'auto', 
            maxHeight: '100%',
            margin: 0,
            padding: '1rem',
            background: '#f5f5f5',
            borderRadius: '4px'
          }}>
            {JSON.stringify(data, null, 2)}
          </pre>
        )}
      </Box>
    </CardContent>
  </Card>
);

export default PlaceholderChart;