import React from 'react';
import { Card, CardHeader, CardContent, Box, Typography } from '@mui/material';
import styled from 'styled-components';

interface DataValueProps {
  title: string;
  type: string;
  size?: 'small' | 'medium' | 'large';
  value: string;
	data: any;
}

const ValueText = styled(Typography)<{size?: 'small' | 'medium' | 'large'}>`
  font-size: ${props => {
    switch(props.size) {
      case 'small':
        return '1.5rem';
      case 'large': 
        return '3rem';
      default:
        return '2rem';
    }
  }};
  font-weight: 500;
  text-align: center;
`;

const DataValue: React.FC<DataValueProps> = ({
  title,
  type,
  size = 'medium',
  value
}) => {
  return (
    <Card>
      <CardHeader 
        title={title}
        subheader={type}
      />
      <CardContent>
        <Box 
          display="flex" 
          justifyContent="center" 
          alignItems="center" 
          minHeight={100}
        >
          <ValueText size={size}>
            {value}
          </ValueText>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DataValue;
