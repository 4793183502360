/**
 * Shared API endpoint constants for services and mock handlers
 */

import { TemplatedMedicalHistoryEditorType } from "../components/general/SmartFields/Templated/TemplatedMedicalHistoryEditor";

// Base API URL, defaults to /api if environment variable is not set
export const API_BASE_URL = import.meta.env.VITE_APP_API_URL || '/api';

// Template endpoints
export const TEMPLATES_ENDPOINT = (uuidInvestigation: string, type:TemplatedMedicalHistoryEditorType) => `${API_BASE_URL}/researcher/investigation/${uuidInvestigation}/templates/${type}`;
export const TEMPLATE_BY_ID_ENDPOINT = (uuidInvestigation: string, templateId: string) => `${API_BASE_URL}/researcher/investigation/${uuidInvestigation}/template/${templateId}`; 