import { blue, green, grey, red, yellow } from "@mui/material/colors";
import { IAgendasConfigParams } from "../constants/types";
import { RequestStatus } from "../pages/hospital/Request/types";

export function canCancelAppointment(appointmentDate:Date, appointmentStatus:RequestStatus, outpatientsInfo:IAgendasConfigParams): boolean {
    if(outpatientsInfo.type === "date"){
        const isTodayOrFutureDate = (appointmentDate > new Date() || appointmentDate.toDateString() === new Date().toDateString());
        console.log("isTodayOrFutureDate", isTodayOrFutureDate);
        return isTodayOrFutureDate && (appointmentStatus !== RequestStatus.ACCEPTED && appointmentStatus !== RequestStatus.CANCELED);
    }
    else if(outpatientsInfo.type === "date_time"){
        return appointmentDate > new Date() && (appointmentStatus !== RequestStatus.ACCEPTED && appointmentStatus !== RequestStatus.CANCELED);
    }
    return false;
}

export function canShowUpAppointment(appointmentDate:Date, appointmentStatus:RequestStatus, outpatientsInfo:IAgendasConfigParams): boolean{
    const isToday = (appointmentDate.toDateString() === new Date().toDateString());
    return isToday && [RequestStatus.PENDING_APPROVAL].includes(appointmentStatus);
}

export function isAppointmentDone(status:number){
    return [RequestStatus.ACCEPTED, RequestStatus.COMPLETED].includes(status);
}

export function turnsAgendaDates(turns:number[][]):Date[]{
    const today = new Date();
    const startTurn = new Date(today);
    startTurn.setHours(turns[0][0]);
    startTurn.setMinutes(turns[0][1]);
    const endTurn = new Date(today);
    endTurn.setHours(turns[1][0]);
    endTurn.setMinutes(turns[1][1]);
    return [startTurn, endTurn];

}

export function errorCodesCreateAppointment(error:number){
    const errorTranslationPath = "pages.hospital.outpatients.appointment.error";
    let message = "";
    if(error === 0){
        message = `${errorTranslationPath}.date_time`
    }
    else if(error === 1){
        message = `${errorTranslationPath}.full_agenda`
    }
    else if(error === 2){
        message = `${errorTranslationPath}.date_blocked`
    }
    else if(error === 3){
        message = `${errorTranslationPath}.week_day_not_available`
    }
    else if(error === 4){
        message = `${errorTranslationPath}.outside_agenda_hours`
    }
    else if(error === 5){
        message = `${errorTranslationPath}.conflict_appointment`
    }
    else if(error === 6){
        message = `${errorTranslationPath}.request_has_appointment`
    }
    else if(error === 7){
        message = `${errorTranslationPath}.request_canceled`
    }
    else{
        return "general.error";
    }
    return message;
}


export const requestStatusToColor = (status: RequestStatus) => {
    switch (status) {
        // In Progress States
        case RequestStatus.PENDING_APPROVAL:
        case RequestStatus.PENDING_PAYMENT:
        case RequestStatus.IN_PROGRESS:
        case RequestStatus.PENDING_ANESTHESIOLOGIST:
        case RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS:
        case RequestStatus.CANCELED_BY_USER:
            return yellow[900];

        // Accepted States    
        case RequestStatus.ACCEPTED:
            return blue[900];
        case RequestStatus.SOME_ACCEPTED:
        case RequestStatus.INCOMPLETE_ACCEPTED:
            return blue[600];
        
        // Completed States
        case RequestStatus.COMPLETED:
        case RequestStatus.ANESTHESIOLOGIST_ACCEPTED:
        case RequestStatus.PAYMENT_COMPLETED:
        case RequestStatus.READY_FOR_SURGERY:
            return green[700];
        
        // Rejected/Error States
        case RequestStatus.CANCELED:
        case RequestStatus.DENIED:
        case RequestStatus.INCOMPLETE:
        case RequestStatus.ANESTHESIOLOGIST_REJECTED:
        case RequestStatus.EXPIRED:
            return red[500];
        
        // Default case
        default:
            return grey[400]; // Light gray for unknown states
    }
}