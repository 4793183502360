// Analytics/services/endpoints/inpatients.js
import axios from 'axios';
import { datalogger } from '../../../../../utils/index.jsx';

const ANALYTICS_BASE_URL = import.meta.env.VITE_APP_API_URL + "/analytics";

const getAuthHeaders = () => ({
    headers: { "Authorization": localStorage.getItem("jwt") }
});

/**
 * Creates full URL for outpatients endpoints
 * @param {string} endpoint - The specific endpoint path (e.g., '/diagnosis')
 * @param {Object} params - Request parameters
 * @returns {string} Complete URL
 */
const buildOutpatientsUrl = (endpoint, { uuidInvestigation, uuidDepartment, startDate, endDate }) => {
    const baseUrl = `${ANALYTICS_BASE_URL}/${uuidInvestigation}/finances`;
    const endpointPath = endpoint || ''; // Handle empty endpoint for base stats
    console.log('Building URL:',endpointPath, uuidDepartment, startDate, endDate);
    return `${baseUrl}/${endpointPath}/${uuidDepartment}/startDate/${startDate}/endDate/${endDate}`;
};

const makeRequest = async (params) => {
    const { endpointPath } = params
    const url = buildOutpatientsUrl(endpointPath, params);
    console.log('Making request to:', params, endpointPath); // Helpful for debugging
    
    try {
        const response = await axios.get(url, getAuthHeaders());
        if (response.status === 200) {
            console.log('Finances API Response:', endpointPath, response.data);
            return response.data;
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching finances data:', error);
        // throw error;
    }
};

const createEndpoint = (endpointPath) => {
    console.log('Creating inpatients endpoint:', endpointPath);
    return datalogger((uuidInvestigation, uuidDepartment, startDate, endDate) => 
        makeRequest({ 
            endpointPath,
            uuidInvestigation, 
            uuidDepartment, 
            startDate, 
            endDate 
        })
    );
};

export const financesServices = {
    getTotalBilling: createEndpoint('total-billing'),
    getTotalDiscount: createEndpoint('total-discount'), 
    getTotalIncome: createEndpoint('total-income'),
    getBillingByLocation: createEndpoint('billing-by-location'),
    getBillingByInsurance: createEndpoint('billing-by-insurance'),
    getBillingByDepartment: createEndpoint('billing-by-department'),
    getMonthlyBillingTrends: createEndpoint('monthly-billing-trends'),
    getOutpatientBillingTrends: createEndpoint('outpatient-billing-trends'),
    getInpatientBillingTrends: createEndpoint('inpatient-billing-trends'),
    getLaboratoryBillingTrends: createEndpoint('laboratory-billing-trends'),
    getRadiologyBilling: createEndpoint('radiology-billing')
}

export const financesEndpoints = {
    TOTAL_BILLING: '/total-billing',
    TOTAL_DISCOUNT: '/total-discount',
    TOTAL_INCOME: '/total-income',
    BILLING_BY_LOCATION: '/billing-by-location',
    BILLING_BY_INSURANCE: '/billing-by-insurance',
    BILLING_BY_DEPARTMENT: '/billing-by-department',
    BILLING_HISTORICAL: '/billing-historical',
    OUTPATIENTS_BILLING: '/outpatients-billing',
    INPATIENTS_BILLING: '/inpatients-billing'
}