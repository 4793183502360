import React, { useMemo } from 'react';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { AgeGroupChart, SexDistributionChart } from './DemographicComponents';
import { IPersonalData } from '../../../../constants/types';


interface DemographicStats {
  totalPatients: number;
  avgAge: string;
  malePercent: string;
  femalePercent: string;
}

interface DemographicsDashboardProps {
  data: IPersonalData[];
}

const DemographicsDashboard: React.FC<DemographicsDashboardProps> = ({ data: patients }) => {
  // Calculate summary statistics
  const stats = useMemo<DemographicStats | null>(() => {
    console.log('patients charts', patients);
    if (!patients?.length) return null;
    
    const total = patients.length;
    const genderCounts: Record<string, number> = patients.reduce((acc, patient) => {
      const gender = patient.sex;
      acc[gender] = (acc[gender] || 0) + 1;
      return acc;
    }, {});
    
    const malePercent = (((genderCounts['Male'] || 0) / total) * 100).toFixed(1);
    const femalePercent = (((genderCounts['Female'] || 0) / total) * 100).toFixed(1);
    
    const ages = patients.map(patient => {
      const birthDate = new Date(patient.birthdate);
      return new Date().getFullYear() - birthDate.getFullYear();
    });
    
    const avgAge = (ages.reduce((a, b) => a + b, 0) / total).toFixed(1);

    return {
      totalPatients: total,
      avgAge,
      malePercent,
      femalePercent
    };
  }, [patients]);

  if (!stats) return null;

  return (
    <Card>
      <CardHeader 
        title="Patient Demographics" 
        subheader={`Total Patients: ${stats.totalPatients} | Average Age: ${stats.avgAge} years | Gender Distribution: ${stats.malePercent}% Male, ${stats.femalePercent}% Female`}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <AgeGroupChart data={patients} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SexDistributionChart data={patients} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DemographicsDashboard;