// Analytics/services/endpoints/inpatients.js
import axios from 'axios';
import { datalogger } from '../../../../../utils/index.jsx';

const ANALYTICS_BASE_URL = import.meta.env.VITE_APP_API_URL + "/analytics";

const getAuthHeaders = () => ({
    headers: { "Authorization": localStorage.getItem("jwt") }
});

/**
 * Creates full URL for outpatients endpoints
 * @param {string} endpoint - The specific endpoint path (e.g., '/diagnosis')
 * @param {Object} params - Request parameters
 * @returns {string} Complete URL
 */
const buildOutpatientsUrl = (endpoint, { uuidInvestigation, uuidDepartment, startDate, endDate }) => {
    const baseUrl = `${ANALYTICS_BASE_URL}/${uuidInvestigation}/radiology`;
    const endpointPath = endpoint || ''; // Handle empty endpoint for base stats
    console.log('Building URL radiology:',endpointPath, uuidDepartment, startDate, endDate);
    return `${baseUrl}/${endpointPath}/${uuidDepartment}/startDate/${startDate}/endDate/${endDate}`;
};

const makeRequest = async (params) => {
    const { endpointPath } = params
    const url = buildOutpatientsUrl(endpointPath, params);
    console.log('Making request to:', params, endpointPath); // Helpful for debugging
    
    try {
        const response = await axios.get(url, getAuthHeaders());
        if (response.status === 200) {
            console.log('radiology API Response:', endpointPath, response.data);
            return response.data;
        }
        console.log('radiology API Response Error:', endpointPath, response.data);
    } catch (error) {
        console.error('Error fetching data: lab', url , error);
        // throw error;
    }
};

const createEndpoint = (endpointPath) => {
    console.log('Creating radiology endpoint:', endpointPath);
    return datalogger((uuidInvestigation, uuidDepartment, startDate, endDate) => 
        makeRequest({ 
            endpointPath,
            uuidInvestigation, 
            uuidDepartment, 
            startDate, 
            endDate 
        })
    );
};

export const radiologyServices = {
    getTotalScans: createEndpoint('total-conducted'),
    getTurnaroundTime: createEndpoint('turnaround-time'), 
    getPatientCount: createEndpoint('patient-count'),
    getMonthlyTrends: createEndpoint('monthly-trends'),
    getLocation: createEndpoint('location')
}

export const RadiologyEndpoints = {
	STATS: '/total-scans',
	BY_DEPARTMENT: '/scans-by-department',
	BY_TYPE: '/scans-by-type',
	AVG_TURNAROUND_TIME: '/average-scan-time',
	MONTHLY_SCANS: '/monthly-scans',
	PENDING_SCANS: '/pending-scans',
	TEST_ACCURACY_RATE: '/test-accuracy-rate'
}