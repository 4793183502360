// Analytics/services/endpoints/inpatients.js
import axios from 'axios';
import { datalogger } from '../../../../../utils/index.jsx';
import { generateRandomData } from './utils';
const ANALYTICS_BASE_URL = import.meta.env.VITE_APP_API_URL + "/analytics";

const getAuthHeaders = () => ({
    headers: { "Authorization": localStorage.getItem("jwt") }
});

/**
 * Creates full URL for outpatients endpoints
 * @param {string} endpoint - The specific endpoint path (e.g., '/diagnosis')
 * @param {Object} params - Request parameters
 * @returns {string} Complete URL
 */
const buildOutpatientsUrl = (endpoint, { uuidInvestigation, uuidDepartment, startDate, endDate }) => {
    const baseUrl = `${ANALYTICS_BASE_URL}/${uuidInvestigation}/inpatients`;
    const endpointPath = endpoint || ''; // Handle empty endpoint for base stats
    console.log('Building URL: inpatients',endpointPath, uuidDepartment, startDate, endDate);
    return `${baseUrl}/${endpointPath}/${uuidDepartment}/startDate/${startDate}/endDate/${endDate}`;
};

const makeOutpatientsRequest = async (params, signal) => {
    const { endpointPath } = params
    const url = buildOutpatientsUrl(endpointPath, params);
    console.log('Making request to:', params, endpointPath); // Helpful for debugging
    
    try {
        const response = await axios.get(url, {
            ...getAuthHeaders(),
            signal
        });
        if (response.status === 200) {
            console.log('Inpatients API Response:', endpointPath, response.data);
            return response.data;
        }

        // return  generateRandomData();
    } catch (error) {
        console.error('Error in inp:', endpointPath, error);

    }
};

const createEndpoint = (endpointPath) => {
    console.log('Creating inpatients endpoint:', endpointPath);
    return datalogger((uuidInvestigation, uuidDepartment, startDate, endDate, signal) => 
        makeOutpatientsRequest({ 
            endpointPath,
            uuidInvestigation, 
            uuidDepartment, 
            startDate, 
            endDate 
        }, signal)
    );
};

export const inpatientsServices = {
    getTotalAdmissions: createEndpoint('total-admissions'),
    getAgeGroup: createEndpoint('total-admissions'),
    getTotalDischarges: createEndpoint('total-discharges'),
    getDischargeRate: createEndpoint('discharge-rate'),
    getAvgStayLength: createEndpoint('average-stay-length'),
    getBedOccupancy: createEndpoint('bed-occupancy'),
    getReadmissionRate: createEndpoint('readmission-rate'),
    getMortalityRate: createEndpoint('mortality-rate'),
    getPharmacyUsage: createEndpoint('pharmacy-usage'),
    getAdmissionsByDepartment: createEndpoint('admissions-by-department'),
    getIndicatorsByDepartment: createEndpoint('indicators-by-department'),
    getDiagnosis: createEndpoint('diagnosis-distribution'),
};