import React from 'react';
import ReactQuill from 'react-quill';
import { QuillWrapper } from '../../FieldSherwood';
import Loader from '../../../Loader';

interface MedicalHistoryEditorProps {
  value: string | null;
  loading: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const MedicalHistoryEditor: React.FC<MedicalHistoryEditorProps> = ({
  value,
  loading,
  onChange,
  onBlur
}) => {
  if (loading) {
    return <Loader />;
  }

  if (!value && !loading) {
    return null;
  }

  return (
    <QuillWrapper>
      <ReactQuill
        style={{ fontSize: '24px' }}
        value={value || ''}
        onChange={(newValue, delta, source) => {
          if (source === 'user') {
            onChange(newValue);
          }
        }}
        onBlur={(range, source, quill) => {
          if (onBlur) {
            onBlur();
          }
        }}
      />
    </QuillWrapper>
  );
};

export default MedicalHistoryEditor; 