import { useEffect, useState } from 'react';

import type { ColumnsType } from 'antd/es/table';
import { EnhancedTable } from '../../../components/general/EnhancedTable';
import axios from 'axios';
import { useResearcher } from '../../../hooks';
import { RequestStatusToChip } from './RequestTable';


interface RequestLog {
  id: number;
  previousStatus: number | null;
  newStatus: number;
  changedAt: string;
  changedBy: {
    id: number;
    researcher: {
      uuid: string;
    }
  }
}

export function useRequestLogs({requestId}: {requestId: number}) { 
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<RequestLog[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRequestLogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios(`${import.meta.env.VITE_APP_API_URL}/hospital/${localStorage.getItem("uuidInvestigation")}/request/${requestId}/status`, { 
            headers: {"Authorization" : localStorage.getItem("jwt")} 
        });
        if (response.status === 200 && response.data) {
            setData(response.data.statusLogs);
        }
        else{
            throw new Error('Failed to fetch request logs');
        }
      } catch (err) {
        const error = err instanceof Error ? err : new Error('Failed to fetch requests');
        setError(error);
        console.error('Error fetching requests:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchRequestLogs();
  }, []);

  return { data, loading, error };
}

const ResearcherName = ({ uuid }: { uuid: string }) => {
  const { researcher, loading, error } = useResearcher(uuid);

  if (loading) return <span>Loading...</span>;
  if (error) return <span>Error loading researcher</span>;
  if (!researcher) return <span>{uuid}</span>;

  return <span>{researcher.name} {researcher.surnames}</span>;
};

const RequestLogTable = ({requestId}:{requestId:number}) => {
  const { data, loading, error } = useRequestLogs({requestId});

  const rows = data.map(log => ({
    id: log.id,
    changedAt: new Date(log.changedAt).toLocaleString(),
    changedBy: <ResearcherName uuid={log.changedBy.researcher.uuid} />,
    previousStatus: log.previousStatus ? <RequestStatusToChip status={log.previousStatus}/> : null,
    newStatus: log.newStatus ? <RequestStatusToChip status={log.newStatus}/> : null
  }));

  const headCells = [
    {
      id: 'id',
      alignment: 'left',
      label: 'Log ID'
    },
    {
      id: 'changedAt',
      alignment: 'left',
      label: 'Date'
    },
    {
      id: 'changedBy',
      alignment: 'left',
      label: 'Changed By'
    },
    {
      id: 'previousStatus',
      alignment: 'left',
      label: 'Previous Status'
    },
    {
      id: 'newStatus',
      alignment: 'left',
      label: 'New Status'
    }
  ];

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <EnhancedTable
      rows={rows}
      headCells={headCells}
      noSelectable={true}
      titleTable="Request Logs"
      loading={loading}
      order={{
        property: 'changedAt',
        orderBy: 'desc'
      }}
    />
  );
};

export default RequestLogTable;
