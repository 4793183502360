import _ from 'lodash';

interface DiagnosisStats {
  name: string;
  amount: number;
  patientIds: number[];
}

interface DiagnosisResponse {
  status: number;
  stats: {
    [diagnosisCode: string]: DiagnosisStats;
  };
  department: string;
  patients: number[];
}

interface ChartSeries {
  name: string;
  data: number[];
}

interface ChartData {
  series: ChartSeries[];
  categories: string[];
}

/**
 * Transforms diagnosis statistics into appointment counts chart data
 * @param diagnosisData The raw diagnosis response data
 * @returns Formatted data for appointments chart
 */
export const transformAppointmentsData = (diagnosisData: DiagnosisResponse): ChartData => {
  if (!diagnosisData?.stats) {
    return {
      series: [],
      categories: []
    };
  }

  // Sort diagnoses by name
  const sortedDiagnoses = _.orderBy(
    Object.entries(diagnosisData.stats),
    ([_, stats]) => stats.name.toLowerCase(),
    ['asc']
  );

  return {
    series: [{
      name: 'Total Appointments',
      data: sortedDiagnoses.map(([_, stats]) => stats.amount)
    }],
    categories: sortedDiagnoses.map(([_, stats]) => stats.name)
  };
};

/**
 * Transforms diagnosis statistics into unique patients chart data
 * @param diagnosisData The raw diagnosis response data
 * @returns Formatted data for unique patients chart
 */
export const transformUniquePatientData = (diagnosisData: DiagnosisResponse): ChartData => {
  if (!diagnosisData?.stats) {
    return {
      series: [],
      categories: []
    };
  }

  // Sort diagnoses by name to match appointments chart
  const sortedDiagnoses = _.orderBy(
    Object.entries(diagnosisData.stats),
    ([_, stats]) => stats.name.toLowerCase(),
    ['asc']
  );

  const UniquePatientsData = sortedDiagnoses.map(([_, stats]) => stats.patientIds.length);
  const TotalPatientsData = sortedDiagnoses.map(([_, stats]) => stats.amount);

  const series = [
    {
      name: 'Unique Patients',
      data: UniquePatientsData
    },
    {
      name: 'Total Patients',
      data: TotalPatientsData
    }
  ];

  return {
    series: series,
    categories: sortedDiagnoses.map(([_, stats]) => stats.name)
  };
};

