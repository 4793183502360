// usePatientFilter.js
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const usePatientFilter = (investigation, departmentSelected, startDate, endDate) => {
    const [loadingPatientsInfo, setLoadingPatientsInfo] = useState(false);
    const [filteredPatients, setFilteredPatients] = useState([]);

     // Get the store state using useSelector
     const patientsStore = useSelector(state => state.patients);

     console.log("patientsStore", patientsStore);   



    return {
        loadingPatientsInfo,
        filteredPatients,
    };
};

export default usePatientFilter;