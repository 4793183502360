// analytics/components/common/ErrorState.tsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

interface ErrorStateProps {
  error?: Error | null;
  onRetry?: () => void;
}

const ErrorState: React.FC<ErrorStateProps> = ({ error, onRetry }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Alert 
        severity="error"
        action={
          onRetry ? (
            <Button color="inherit" size="small" onClick={onRetry}>
              RETRY
            </Button>
          ) : undefined
        }
      >
        <AlertTitle>Error Loading Data</AlertTitle>
        {error?.message || 'An error occurred while loading the data. Please try again later.'}
      </Alert>
    </Box>
  );
};

export default ErrorState;