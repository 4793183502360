import React, { useMemo } from 'react';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import { Bar, Pie } from 'react-chartjs-2';
import { IPersonalData } from '../../../../constants/types';


interface ChartProps {
  data: IPersonalData[];
  title?: string;
}

// AgeGroupChart Component
export const AgeGroupChart: React.FC<ChartProps> = ({ data: patients, title = 'Age Distribution' }) => {
  const ageGroupData = useMemo(() => {
    const ageGroups: Record<string, number> = {
      '0-17': 0,
      '18-30': 0,
      '31-45': 0,
      '46-60': 0,
      '61-75': 0,
      '75+': 0
    };

    patients.forEach(patient => {
      const birthDate = new Date(patient.birthdate);
      const age = new Date().getFullYear() - birthDate.getFullYear();
      
      if (age <= 17) ageGroups['0-17']++;
      else if (age <= 30) ageGroups['18-30']++;
      else if (age <= 45) ageGroups['31-45']++;
      else if (age <= 60) ageGroups['46-60']++;
      else if (age <= 75) ageGroups['61-75']++;
      else ageGroups['75+']++;
    });

    return {
      labels: Object.keys(ageGroups),
      datasets: [{
        label: 'Number of Patients',
        data: Object.values(ageGroups),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      }]
    };
  }, [patients]);

  const options: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    scales: {
      y: {
        beginAtZero: boolean;
        ticks: {
          stepSize: number;
        };
      };
    };
    plugins: {
      legend: {
        position: 'top';
      };
      title: {
        display: boolean;
      };
    };
  } = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false
      }
    }
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box height={300}>
          <Bar data={ageGroupData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

// SexDistributionChart Component
export const SexDistributionChart: React.FC<ChartProps> = ({ data: patients, title = 'Sex Distribution' }) => {
  const genderData = useMemo(() => {
    const distribution: Record<string, number> = patients.reduce((acc, patient) => {
      const gender = patient.sex;
      acc[gender] = (acc[gender] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    return {
      labels: ['Male', 'Female'],
      datasets: [{
        data: [distribution['Male'] || 0, distribution['Female'] || 0],
        backgroundColor: [
          'rgba(54, 162, 235, 0.5)',
          'rgba(255, 99, 132, 0.5)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1,
      }]
    };
  }, [patients]);

  const options: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    plugins: {
      legend: {
        position: 'top';
      };
      tooltip: {
        callbacks: {
          label: (context: any) => string;
        };
      };
    };
  } = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const total = genderData.datasets[0].data.reduce((a, b) => a + b, 0);
            const percentage = ((context.raw / total) * 100).toFixed(1);
            return `${context.label}: ${context.raw} (${percentage}%)`;
          }
        }
      }
    }
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box height={300}>
          <Pie data={genderData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};