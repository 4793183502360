import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Grid,
    Typography
} from '@mui/material';

// TypeScript interfaces (these will be ignored in JS but help with documentation)
interface ProductivityStats {
    totalDoctors: number;
    totalFirstVisits: number;
    totalFollowUps: number;
    totalFreeConsultations: number;
    totalAppointments: number;
    totalUniquePatients: number;
}

interface DoctorData {
    researcher_uuid: string;
    doctor_name: string;
    doctor_surnames: string;
    department_name: string;
    total_appointments: number;
    unique_patients: number;
    first_visits: number;
    follow_ups: number;
    free_consultation: number;
}

interface ProductivityTableProps {
    stats: ProductivityStats;
    data: DoctorData[];
    department: string;
    showOptionalColumns?: boolean;
}

const StatsCard = ({ title, value }) => (
    <Card className="h-full">
        <CardContent>
            <Typography color="textSecondary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="h5" component="div">
                {value}
            </Typography>
        </CardContent>
    </Card>
);

const ProductivityTable = ({ 
    stats, 
    data, 
    department,
    showOptionalColumns = false 
}: ProductivityTableProps) => {
    const maxRows = 7;
    
    // If department is "all", only show stats
    // const showDetailedTable = department !== "all";
    const showDetailedTable = true;
   


    return (
        <div className="space-y-2">
            {/* Stats Summary Cards */}
            <Grid container spacing={2} className="mb-4">
                <Grid item xs={12} sm={6} md={4}>
                    <StatsCard 
                        title="Total Doctors" 
                        value={stats.totalDoctors} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StatsCard 
                        title="Total Appointments" 
                        value={stats.totalAppointments} 
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <StatsCard 
                        title="Unique Patients" 
                        value={stats.totalUniquePatients} 
                    />
                </Grid>
            </Grid>

            {/* Detailed Table */}
            {showDetailedTable && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Doctor</strong></TableCell>
                                <TableCell align="right"><strong>Total Appointments</strong></TableCell>
                                <TableCell align="right"><strong>Unique Patients</strong></TableCell>
                                <TableCell align="right"><strong>First Visits</strong></TableCell>
                                {showOptionalColumns && (
                                    <>
                                        <TableCell align="right"><strong>Follow Ups</strong></TableCell>
                                        <TableCell align="right"><strong>Free Consultations</strong></TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.slice(0, maxRows).map((row) => (
                                <TableRow
                                    key={row.researcher_uuid}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {`${row.doctor_name} ${row.doctor_surnames}`}
                                    </TableCell>
                                    <TableCell align="right">{row.total_appointments}</TableCell>
                                    <TableCell align="right">{row.unique_patients}</TableCell>
                                    <TableCell align="right">{row.first_visits}</TableCell>
                                    {showOptionalColumns && (
                                        <>
                                            <TableCell align="right">{row.follow_ups}</TableCell>
                                            <TableCell align="right">{row.free_consultation}</TableCell>
                                        </>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default ProductivityTable;