// analytics/hooks/useLabData.ts
import { useState, useEffect } from 'react';

import { labServices } from '../services/endpoints/laboratory';

interface LabData {
  totalTests: any;
  testsByDepartment: any;
  testsByType: any;
  averageTurnaroundTime: any;
  monthlyTests: any;
  patients: any;
  location: any;
  insurance: any;
  type: any;
}

export const labMethodMap = {
  totalTests: labServices.getTotalTests,
  testsByDepartment: labServices.getTestsByDepartment,
  testsByType: labServices.getTestsByType,
  averageTurnaroundTime: labServices.getAverageTurnaroundTime,
  monthlyTests: labServices.getMonthlyTests,
  patients: labServices.getPatients,
  location: labServices.getLocation,
  insurance: labServices.getInsurance,
  type: labServices.getType,
};

interface UseLabDataOptions {
  investigation: { uuid: string };
  departmentSelected?: string;
  startDate: string;
  endDate: string;
  requestedMethod: keyof typeof labMethodMap; // e.g. "totalTests"
}

export function useLabData({
  investigation,
  departmentSelected,
  startDate,
  endDate,
  requestedMethod,
}: UseLabDataOptions) {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const uuidDepartment = departmentSelected || 'all';

    // If required fields aren’t available, don't bother fetching
    if (!investigation?.uuid || !startDate || !endDate) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // Grab the appropriate function from the map
        const fetchMethod = labMethodMap[requestedMethod];
        if (!fetchMethod) {
          throw new Error(`Unknown method: ${requestedMethod}`);
        }

        const response = await fetchMethod(
          investigation.uuid,
          uuidDepartment,
          startDate,
          endDate
        );
        setData(response);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [investigation, departmentSelected, startDate, endDate, requestedMethod]);

  return { data, loading, error };
}