import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/**
 * DatePickerProvider component that provides date picker functionality to the application.
 * This replaces the older MuiPickersUtilsProvider with the new MUI v5 LocalizationProvider.
 */
const DatePickerProvider = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {children}
    </LocalizationProvider>
  );
};

export default DatePickerProvider; 