import { Grid, Typography } from "@mui/material"
import { useState } from "react"
import { LocalizeContextProps, Translate, withLocalize } from "react-localize-redux"
import { ButtonEdit, FieldWrapper } from "../mini_components"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { EDDType } from "."

interface Props extends LocalizeContextProps {
    error:boolean,
    elementSelected:(edd:EDDType) => void
}

const EDDField:React.FC<Props> = (props) => {
    const [eddDate, setEDDate] = useState<Date | null>(null);
    const [lastPeriod, setLastPeriod] = useState<Dayjs | null>(null);

    function handleDateChange(value: Dayjs | null){
        if(value !== null){
            // Calculate EDD: 280 days after last period
            let cloneDate = new Date(value.valueOf());
            cloneDate.setDate(cloneDate.getDate() + 280);
            setEDDate(cloneDate);
            setLastPeriod(value);
            const eddElement:EDDType = {edd:cloneDate, edd_last_period: value.toDate()};
            props.elementSelected(eddElement);
        }
    }
    
    function editDate(){
        setEDDate(null);
        setLastPeriod(null);
    }
    
    const labelString = props.translate("hospital.edd-title").toString();
    
    return(
    <Grid container spacing={3}>
        <Grid item xs={12}>
            <Typography variant="body2" component="span"><Translate id="hospital.edd"></Translate></Typography>
        </Grid>
        {
            !eddDate &&
            <Grid item xs={12}>
                <FieldWrapper noWrap = {true}>
                    <DatePicker
                        label={labelString}
                        value={lastPeriod}
                        onChange={handleDateChange}
                        maxDate={dayjs()} // Today as max date
                        format="DD/MM/YYYY"
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                variant: 'outlined'
                            }
                        }}
                    />
                </FieldWrapper>
            </Grid>
        }
        {
            eddDate &&
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="span">Result : {eddDate.toLocaleDateString()}</Typography>
                    <ButtonEdit onClick={editDate} ><Translate id="general.edit" /></ButtonEdit>
                </Grid>
            </Grid>
        }
    </Grid>
    )
}

export default withLocalize(EDDField);