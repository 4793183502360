// config/analyticsConfig.js
import { green, red, orange, yellow, blue, amber, brown, cyan, deepOrange } from "@mui/material/colors";

 const analyticsTabsList = [
  { 
    id: 'outpatients', 
    label: 'Outpatients',
    color: blue[500],
    enabled: true
  },
  { 
    id: 'inpatients', 
    label: 'Inpatients',
    color: green[500],
    enabled: true
  },
  { 
    id: 'laboratory', 
    label: 'Laboratory',
    color: orange[500],
    enabled: true
  },
  { 
    id: 'radiology', 
    label: 'Radiology',
    color: red[500],
    enabled: true
  },
  { 
    id: 'finances', 
    label: 'Finances',
    color: brown[500],
    enabled: true
  },
  { 
    id: 'staff', 
    label: 'Staff',
    color: cyan[500],
    enabled: false
  },
  { 
    id: 'quality', 
    label: 'Quality Indicators',
    color: deepOrange[500],
    enabled: false
  }
];

export const analyticsTabs = analyticsTabsList.filter(tab => tab.enabled);

export const analyticsConfig = {
  defaultColor: blue[500],
  activeColor: blue[700],
  subtitle: 'Centre Hospitalier de l\'Ordre de Malte (CHOM) à Dakar',
  defaultTab: 'outpatients',
  scrollButtons: true,
  tabVariant: 'scrollable'
};