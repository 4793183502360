import React, { useMemo } from 'react';
import { IDepartment } from '../../../../constants/types';
import BarChart from '../../../dashboards/Analytics/BarChart';
import MultiChart from './MultiChart';

interface PatientsBarChartProps {
    departments: IDepartment[];
    departmentSelected?: IDepartment;
    statsPerDepartment: StatsPerDepartment;
    patients: any[];
    title: React.ReactNode;
}

interface StatsPerDepartment {
    [date: string]: Array<{
        idPatientInvestigation: number;
        department: {
            id: number;
            uuid: string;
            name: string;
        };
    }>;
}

type VisitsPerDatePerDepartment = {
    [date: string]: {
        [departmentName: string]: number;
    };
};

interface Series {
    name: string,
    data: number[]

}

interface BarChartProps{
    series: Series[],
    title:string,
    categories:string[]
}

function transformToBarChartProps(data: Record<string, Record<string, number>>): BarChartProps {
    const categories = Object.keys(data); // Dates as categories
    const departmentSet = new Set<string>();

    // Collect all department names
    categories.forEach(date => {
        Object.keys(data[date]).forEach(department => departmentSet.add(department));
    });

    // Initialize series
    const series: Series[] = Array.from(departmentSet).map(department => ({
        name: department,
        data: categories.map(date => data[date][department] || 0) // Use 0 if no count exists for a department on a date
    }));

    return {
        series,
        title: "Visits Per Department", // Replace with an appropriate title or JSX element
        categories
    };
}

function calculateVisitsPerDatePerDepartment(statsPerDepartment: StatsPerDepartment): BarChartProps {
    const result: VisitsPerDatePerDepartment = {};
    
    // Get all dates and sort them
    const dates = Object.keys(statsPerDepartment).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // Process each date in order
    for (const date of dates) {
        const visits = statsPerDepartment[date];
        const departmentCounts: { [departmentName: string]: number } = {};

        visits.forEach((visit) => {
            const departmentName = visit.department.name;
            if (!departmentCounts[departmentName]) {
                departmentCounts[departmentName] = 0;
            }
            departmentCounts[departmentName]++;
        });

        result[date] = departmentCounts;
    }

    const barChartProps = transformToBarChartProps(result);
    return barChartProps;
}


const PatientsBarChart: React.FC<PatientsBarChartProps> = ({ 
    departments, 
    title, 
    departmentSelected, 
    statsPerDepartment 
}) => {
    

    const barChartProps = useMemo(() => 
        calculateVisitsPerDatePerDepartment(statsPerDepartment),
        [statsPerDepartment]
    );

    console.log(' BarChartProps:', barChartProps);

    if (!barChartProps.categories.length || !barChartProps.series.length) {
        return null;
    }

    return (
        <MultiChart 
            title={barChartProps.title}
            categories={barChartProps.categories}
            series={barChartProps.series}
            type='line'
            stacked={false}
            horizontal={false}
        />
    );
};

export default PatientsBarChart;