import { debug } from "console";

// config/analyticsCharts.jso
//   type: 'bar' | 'line' | 'pie' | 'doughnut' | 'gauge' | 'demographic' | 'data-only' | 'data-value' | 'patient-bar' | 'productivity' | 'multi-chart' | 'donut' | 'bar-chart' | 'horizontal-bar' | 'appointment-stats' | 'distribuited';
type ChartType = 
  | 'bar' 
  | 'line' 
  | 'pie' 
  | 'doughnut' 
  | 'gauge' 
  | 'demographic' 
  | 'data-only' 
  | 'data-value' 
  | 'patient-bar' 
  | 'productivity' 
  | 'multi-chart' 
  | 'donut' 
  | 'bar-chart' 
  | 'horizontal-bar' 
  | 'appointment-stats' 
  | 'distribuited';

export interface ChartConfig {
    id: string;
    source: string;
    order: number;
    title: string;
    type: ChartType;
    cols: number;
    visible: boolean;
    debug?: boolean;
  }
  
  interface AnalyticsChartsConfig {
    outpatients: ChartConfig[];
    inpatients: ChartConfig[];
    laboratory: ChartConfig[];
    radiology: ChartConfig[];
    finances: ChartConfig[];
    staff: ChartConfig[];
    quality: ChartConfig[];
  }

export const analyticsCharts: AnalyticsChartsConfig = {
	outpatients: [
    { id: 'totalPatients', source: 'totalPatients', order: 1, title: 'Total Number of Patients', type: 'distribuited', cols: 6, visible: true, debug: false },
    { id: 'totalAppointments', source: 'appointmentDistribution', order: 2, title: 'Total Appointments', type: 'line', cols: 6, visible: true },
    { id: 'patientsDetails', source: 'patientsDetails', order: 3, title: 'Age Group  and Sex Distribution', type: 'demographic', cols: 12, visible: true },
    { id: 'sexDistribution', source: 'sexDistribution', order: 4, title: 'Sex Distribution', type: 'sexDistribution', cols: 6, visible: false },
    { id: 'newPatients', source: 'newPatients', order: 5, title: 'Total New Patients Registered', type: 'distribuited', cols: 6, visible: true, debug: false },
    { id: 'appointmentsByType', source: 'appointmentsByType', order: 6, title: 'Type of Appointments', type: 'distribuited', cols: 6, visible: true, debug: false },
    { id: 'averageWaitingTimes', source: 'averageWaitingTimes', order: 7, title: 'Average Waiting Times', type: 'bar', cols: 6, visible: false },
    { id: 'noShowRate', source: 'overdueAppointments', order: 8, title: 'No Show Rate', type: 'appointment-stats', cols: 12, visible: true, debug: false },
    { id: 'pharmacyUsage', source: 'pharmacyUsage', order: 9, title: 'Pharmacy Usage', type: 'bar', cols: 6, visible: false },
    { id: 'monthlyAppointments', source: 'appointmentsByType', order: 10, title: 'Total Appointments Per Month', type: 'data-only', cols: 12, visible: false, debug: false},
    { id: 'appointmentsByDepartment', source: 'appointementStats', order: 11, title: 'Total Appointments by Department', type: 'data-only', cols: 12, visible: false },
    { id: 'productivity', source: 'appointmentsByDoctor', order: 12, title: 'Productivity', type: 'productivity', cols: 6, visible: true, debug: false },
    { id: 'diagnosis', source: 'diagnosis', order: 13, title: 'Diagnosis Distribution', type: 'multi-chart', cols: 6, visible: true, debug: false },
    { id: 'debug', source: 'activityDistribution', order: 14, title: 'Debug', type: 'patient-bar', cols: 12, visible: false, debug: false },
	],
	inpatients: [
    { id: 'totalAdmissions', source: 'totalAdmissions', order: 1, title: 'Total Number of Admissions', type: 'horizontal-bar', cols: 6, visible: true, debug: false },
    { id: 'ageGroup', source: 'ageGroup', order: 2, title: 'Age Group Distribution', type: 'data-only', cols: 6, visible: false, debug: false },
    { id: 'totalDischarges', source: 'totalDischarges', order: 3, title: 'Total Number of Discharges', type: 'donut', cols: 6, visible: true, debug: false },
    { id: 'dischargeRate', source: 'dischargeRate', order: 4, title: 'Discharge Rate', type: 'horizontal-bar', cols: 6, visible: true, debug: false },
    { id: 'avgStayLength', source: 'avgStayLength', order: 5, title: 'Average Length of Stay', type: 'bar-chart', cols: 6, visible: true, debug: false },
    { id: 'bedOccupancy', source: 'bedOccupancy', order: 6, title: 'Bed Occupancy Rate', type: 'pie', cols: 6, visible: true, debug: false },
    { id: 'readmissionRate', source: 'readmissionRate', order: 7, title: 'Re-admission Rate', type: 'horizontal-bar', cols: 6, visible: true },
    { id: 'mortalityRate', source: 'mortalityRate', order: 8, title: 'Mortality Rate', type: 'bar-chart', cols: 6, visible: true },
    { id: 'pharmacyUsage', source: 'pharmacyUsage', order: 9, title: 'Pharmacy Usage', type: 'line', cols: 6, visible: false },
    { id: 'admissionsByDepartment', source: 'admissionsByDepartment', order: 10, title: 'Admissions by Department', type: 'bar', cols: 12, visible: false },
    { id: 'indicatorsByDepartment', source: 'indicatorsByDepartment', order: 11, title: 'Indicators by Department', type: 'bar', cols: 6, visible: false, debug: false },
    { id: 'diagnosis', source: 'diagnosis', order: 12, title: 'Diagnosis Distribution', type: 'donut', cols: 6, visible: true, debug: false },
	],
	laboratory: [
    { id: 'testsByDepartment', source: 'totalTests', order: 2, title: 'Tests by Department', type: 'pie', cols: 6, visible: true },
    { id: 'testsByType', source: 'testsByType', order: 3, title: 'Tests by Type', type: 'doughnut', cols: 6, visible: false },
    { id: 'averageTurnaroundTime', source: 'averageTurnaroundTime', order: 4, title: 'Average Turnaround Time', type: 'bar-chart', cols: 6, visible: true, debug: false },
    { id: 'monthlyTests', source: 'monthlyTests', order: 5, title: 'Monthly Tests', type: 'distribuited', cols: 6, visible: true },
    { id: 'patients', source: 'patients', order: 6, title: 'Number of Patients', type: 'bar-chart', cols: 6, visible: true },
    { id: 'location', source: 'location', order: 7, title: 'Test by Location', type: 'bar-chart', cols: 6, visible: true },
    { id: 'insurance', source: 'insurance', order: 8, title: 'Test by Insurance', type: 'pie', cols: 6, visible: true },
    { id: 'type', source: 'type', order: 9, title: 'Test by Type', type: 'bar-chart', cols: 6, visible: true }
],
radiology: [
    { id: 'totalScans', source: 'totalScans', order: 1, title: 'Total Scans Conducted', type: 'bar-chart', cols: 6, visible: true, debug: false },
    { id: 'turnaroundTime', source: 'turnaroundTime', order: 2, title: 'Average Turnaround Time', type: 'bar-chart', cols: 6, visible: true },
    { id: 'patientCount', source: 'patientCount', order: 3, title: 'Patient Count', type: 'distribuited', cols: 6, visible: true },
    { id: 'monthlyTrends', source: 'monthlyTrends', order: 4, title: 'Monthly Trends', type: 'line', cols: 12, visible: true },
    { id: 'location', source: 'location', order: 5, title: 'Location', type: 'pie', cols: 6, visible: true }
],
finances: [
    { id: 'totalBilling', source: 'totalBilling', order: 1, title: 'Total Billing', type: 'bar-chart', cols: 6, visible: true },
    { id: 'totalDiscount', source: 'totalDiscount', order: 2, title: 'Total Discounts', type: 'bar-chart', cols: 6, visible: true },
    { id: 'totalIncome', source: 'totalIncome', order: 3, title: 'Total Income', type: 'bar-chart', cols: 6, visible: true, debug: false },
    { id: 'billingByLocation', source: 'billingByLocation', order: 4, title: 'Billing by Location', type: 'pie', cols: 6, visible: true },
    { id: 'billingByInsurance', source: 'billingByInsurance', order: 5, title: 'Billing by Insurance', type: 'pie', cols: 6, visible: false },
    { id: 'billingByDepartment', source: 'billingByDepartment', order: 6, title: 'Billing by Department', type: 'bar-chart', cols: 6, visible: false },
    { id: 'monthlyBillingTrends', source: 'monthlyBillingTrends', order: 7, title: 'Monthly Billing Trends', type: 'line', cols: 12, visible: false },
    { id: 'outpatientBillingTrends', source: 'outpatientBillingTrends', order: 8, title: 'Outpatient Billing Trends', type: 'line', cols: 6, visible: false },
    { id: 'inpatientBillingTrends', source: 'inpatientBillingTrends', order: 9, title: 'Inpatient Billing Trends', type: 'line', cols: 6, visible: false },
    { id: 'laboratoryBillingTrends', source: 'laboratoryBillingTrends', order: 10, title: 'Laboratory Billing Trends', type: 'line', cols: 6, visible: false },
    { id: 'radiologyBilling', source: 'radiologyBilling', order: 11, title: 'Radiology Billing', type: 'bar-chart', cols: 6, visible: false }
],

staff: [
	{ id: 'totalStaff', source: 'totalStaff', order: 1, title: 'Total Staff', type: 'bar', cols: 6, visible: true },
	{ id: 'medicalStaff', source: 'medicalStaff', order: 2, title: 'Medical Staff', type: 'bar', cols: 6, visible: true },
	{ id: 'staffByDepartment', source: 'staffByDepartment', order: 3, title: 'Staff by Department', type: 'pie', cols: 6, visible: true },
	{ id: 'staffByCategory', source: 'staffByCategory', order: 4, title: 'Staff by Category', type: 'doughnut', cols: 6, visible: true },
	{ id: 'staffingLevels', source: 'staffingLevels', order: 5, title: 'Staffing Levels', type: 'line', cols: 12, visible: true },
	{ id: 'staffingMix', source: 'staffingMix', order: 6, title: 'Staffing Mix', type: 'bar', cols: 6, visible: true }
],

quality: [
	{ id: 'outpatientsQuality', source: 'outpatientsQuality', order: 1, title: 'Outpatients Quality', type: 'bar', cols: 6, visible: true },
	{ id: 'inpatientsQuality', source: 'inpatientsQuality', order: 2, title: 'Inpatients Quality', type: 'bar', cols: 6, visible: true },
	{ id: 'staffQuality', source: 'staffQuality', order: 3, title: 'Staff Quality', type: 'bar', cols: 6, visible: true },
	{ id: 'requestsQuality', source: 'requestsQuality', order: 4, title: 'Requests Quality', type: 'line', cols: 12, visible: true }
],
 };