import { useState, useCallback, useEffect } from 'react';
import { createSelector } from '@reduxjs/toolkit'; 
import { AgendasTypes, IAgenda, IAppointment, IBox } from '../constants/types';
import { 
    saveBoxService, 
    updateBoxService, 
    deleteBoxService, 
    getBoxesService, 
    getAppointmentService
} from '../services/agenda';
import { useSnackBarState } from '../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getAgendasInvestigationAction } from '../redux/actions/hospitalActions';
import { 
    getServicesInvestigationService, 
    updateServiceInvestigationService,
    saveServiceInvestigationService,
} from '../services/agenda';
import { IServiceInvestigation, ServiceType } from '../pages/hospital/Request/types';
import { deleteAgendaAction, saveUpdateAgendaAction } from '../redux/actions/hospitalActions';
import { deleteAgendaService, saveAgendaService, updateAgendaService } from '../services/agenda';

interface BoxOperationsResult {
    boxes: IBox[];
    loading: boolean;
    error: string | null;
    // Operations
    fetchBoxes: () => Promise<void>;
    saveBox: (box: IBox) => Promise<void>;
    updateBox: (box: IBox) => Promise<void>;
    deleteBox: (uuidBox: string) => Promise<void>;
    // Utility functions
    canDeleteBox: (box: IBox, agendas: any[]) => boolean;
    findBoxByUuid: (uuid: string) => IBox | undefined;
}

export const useBoxOperations = (uuidInvestigation:string, typeAgenda:AgendasTypes): BoxOperationsResult => {
    const [boxes, setBoxes] = useState<IBox[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showSnackbar, setShowSnackbar] = useSnackBarState();

    const handleError = useCallback((err: any) => {
        const message = err.errorCode !== undefined 
            ? `pages.hospital.outpatients.box.errors.error_${err.errorCode}` 
            : err.message;
        setError(message);
        setShowSnackbar({
            show: true,
            message: message,
            severity: "error"
        });
    }, [setShowSnackbar]);

    const fetchBoxes = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getBoxesService(uuidInvestigation, typeAgenda);
            setBoxes(response.boxes);
            setError(null);
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [handleError]);

    const saveBox = useCallback(async (box: IBox) => {
        box.type = typeAgenda === AgendasTypes.CONSULTATION ? 0 : 1;
        setLoading(true);
        try {
            const response = await saveBoxService(uuidInvestigation, box);
            setBoxes(prevBoxes => [...prevBoxes, response.box]);
            setShowSnackbar({
                show: true,
                message: "pages.hospital.outpatients.box.success.saved",
                severity: "success"
            });
            setError(null);
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [handleError, setShowSnackbar]);

    const updateBox = useCallback(async (box: IBox) => {
        setLoading(true);
        try {
            const response = await updateBoxService(uuidInvestigation, box, typeAgenda);
            setBoxes(prevBoxes => {
                const boxIndex = prevBoxes.findIndex(b => b.uuid === response.box.uuid);
                if (boxIndex === -1) return prevBoxes;
                
                const newBoxes = [...prevBoxes];
                newBoxes[boxIndex] = response.box;
                return newBoxes;
            });
            setShowSnackbar({
                show: true,
                message: "pages.hospital.outpatients.box.success.updated",
                severity: "success"
            });
            setError(null);
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [handleError, setShowSnackbar]);

    const deleteBox = useCallback(async (uuidBox: string) => {
        setLoading(true);
        try {
            await deleteBoxService(uuidInvestigation, uuidBox);
            setBoxes(prevBoxes => prevBoxes.filter(box => box.uuid !== uuidBox));
            setShowSnackbar({
                show: true,
                message: "pages.hospital.outpatients.box.success.deleted",
                severity: "success"
            });
            setError(null);
        } catch (err: any) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [handleError, setShowSnackbar]);

    // Utility functions
    const canDeleteBox = useCallback((box: IBox, agendas: any[]) => {
        // Check if box has any associated agendas
        return !agendas.some(agenda => 
            (agenda.box as IBox).uuid === box.uuid
        );
    }, []);

    const findBoxByUuid = useCallback((uuid: string) => {
        return boxes.find(box => box.uuid === uuid);
    }, [boxes]);

    useEffect(() => {
        fetchBoxes();
    }, [uuidInvestigation, typeAgenda]);

    return {
        boxes,
        loading,
        error,
        fetchBoxes,
        saveBox,
        updateBox,
        deleteBox,
        canDeleteBox,
        findBoxByUuid
    };
};

//Used this approach to avoid re-rendering the component, since filter returns a new array every time
const selectAgendas = createSelector(
    (state: any) => state.hospital.data.agendas,
    (state: any, type: AgendasTypes) => type,
    (agendas, type) => {
        if (!agendas) return null;
        const agendasFiltered = agendas.filter((agenda: IAgenda) => agenda.type === type);
        return agendasFiltered.length > 0 ? agendasFiltered : [];
    }
);

export function useAgendas(type: AgendasTypes) {
    const investigations = useSelector((state: any) => state.investigations);
    const agendas = useSelector((state: any) => selectAgendas(state, type));
    
    const loadingAgendas = useSelector((state: any) => state.hospital.loading || state.investigations.loading);
    const dispatch = useDispatch();
    
    useEffect(() => {
        async function getAgendas(uuidInvestigation: string, type: AgendasTypes) {
            await dispatch(
                getAgendasInvestigationAction(uuidInvestigation, type)
            ); 
        }
        if (investigations.data && investigations.currentInvestigation && (!agendas || agendas.length === 0)) {
            getAgendas(investigations.currentInvestigation.uuid, type)
        }
    }, [investigations])

    return { agendas, loadingAgendas }
}

interface ServiceOperationsResult {
    services: IServiceInvestigation[];
    loading: boolean;
    error: string | null;
    saveService: (service: IServiceInvestigation) => Promise<void>;
    updateService: (service: IServiceInvestigation) => Promise<void>;
    deleteService: (idService: number) => Promise<void>;
}

export const useServiceOperations = (uuidInvestigation: string, typeAgenda:AgendasTypes): ServiceOperationsResult => {
    const [services, setServices] = useState<IServiceInvestigation[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [showSnackbar, setShowSnackbar] = useSnackBarState();
    const serviceType = typeAgenda === AgendasTypes.CONSULTATION ? ServiceType.CONSULTATION : ServiceType.SURGERY;

    useEffect(() => {
        setLoading(true);
        
        getServicesInvestigationService(uuidInvestigation, serviceType)
            .then(response => {
                setServices(response.servicesInvestigation);
                setError(null);
            })
            .catch(err => {
                setError(err.message);
                setShowSnackbar({show: true, message: err.message, severity: "error"})
            })
            .finally(() => {
                setLoading(false);
            });
    }, [uuidInvestigation]);

    const updateService = async (service: IServiceInvestigation) => {
        setLoading(true);
        try {
            const response = await updateServiceInvestigationService(uuidInvestigation, service);
            setServices(prevServices => {
                const index = prevServices.findIndex(s => s.id === response.serviceInvestigation.id);
                const newServices = [...prevServices];
                newServices[index] = response.serviceInvestigation;
                return newServices;
            });
            setShowSnackbar({show: true, message: "pages.hospital.outpatients.service.success.updated", severity: "success"});
        } catch (err: any) {
            setError(err.message);
            setShowSnackbar({show: true, message: "general.error", severity: "error"});
        } finally {
            setLoading(false);
        }
    };

    const saveService = async (service: IServiceInvestigation) => {
        setLoading(true);
        try {
            const response = await saveServiceInvestigationService(uuidInvestigation, service);
            setServices(prevServices => [...prevServices, response.serviceInvestigation]);
            setShowSnackbar({show: true, message: "pages.hospital.outpatients.box.success.saved", severity: "success"});
        } catch (err: any) {
            setError(err.message);
            setShowSnackbar({show: true, message: "general.error", severity: "error"});
        } finally {
            setLoading(false);
        }
    };

    const deleteService = async (idService: number) => {
        setLoading(true);
        try {
            await deleteServiceService(uuidInvestigation, idService);
            setServices(prevServices => {
                const newServices = [...prevServices];
                const index = newServices.findIndex(s => s.id === idService);
                newServices.splice(index, 1);
                return newServices;
            });
            setShowSnackbar({show: true, message: "pages.hospital.outpatients.service.success.deleted", severity: "success"});
        } catch (err: any) {
            setError(err.message);
            setShowSnackbar({show: true, message: "general.error", severity: "error"});
        } finally {
            setLoading(false);
        }
    };

    return {
        services,
        loading,
        error,
        saveService,
        updateService,
        deleteService
    };
};


export const useAgendaOperations = (uuidInvestigation: string, typeAgenda:AgendasTypes) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const deleteAgenda = async (uuidAgenda: string) => {
        setLoading(true);
        try {
            await deleteAgendaService(uuidInvestigation, uuidAgenda);
            await dispatch(deleteAgendaAction(uuidAgenda));
            setLoading(false);
            return {
                success: true,
                message: "pages.hospital.outpatients.agenda.success.deleted"
            };
        } catch (err: any) {
            setLoading(false);
            const message = err.response?.data?.errorCode !== undefined 
                ? "pages.hospital.outpatients.agenda.errors.error_" + err.response.data.errorCode 
                : err.message;
            return {
                success: false,
                message
            };
        }
    };

    const saveAgenda = async (agenda: IAgenda) => {
        setLoading(true);
        try {
            const response = await saveAgendaService(uuidInvestigation, agenda, typeAgenda);
            await dispatch(saveUpdateAgendaAction(response.agenda));
            setLoading(false);
            return {
                success: true,
                message: "pages.hospital.outpatients.agenda.success.saved"
            };
        } catch (err: any) {
            setLoading(false);
            const message = err.response?.data?.errorCode !== undefined 
                ? "pages.hospital.outpatients.agenda.errors.error_" + err.response.data.errorCode 
                : err.message;
            return {
                success: false,
                message
            };
        }
    };

    const updateAgenda = async (agenda: IAgenda) => {
        setLoading(true);
        try {
            const response = await updateAgendaService(uuidInvestigation, agenda, typeAgenda);
            await dispatch(saveUpdateAgendaAction(response.agenda));
            setLoading(false);
            return {
                success: true,
                message: "pages.hospital.outpatients.agenda.success.updated"
            };
        } catch (err: any) {
            setLoading(false);
            const message = err.response?.data?.errorCode !== undefined 
                ? "pages.hospital.outpatients.agenda.errors.error_" + err.response.data.errorCode 
                : err.message;
            return {
                success: false,
                message
            };
        }
    };

    return {
        loading,
        deleteAgenda,
        saveAgenda,
        updateAgenda
    };
};

export function useAppointment(uuidAppointment:string, uuidInvestigation:string){
    const [appointment, setAppointment] = useState<IAppointment | null>(null);
    const [loadingAppointment, setLoadingAppointment] = useState(false);

    useEffect(() => {
        setLoadingAppointment(true);
        getAppointmentService(uuidAppointment, uuidInvestigation)
            .then(response => {
                setAppointment(response.appointment);
            })
            .finally(() => {
                setLoadingAppointment(false);
            });
    }, [uuidAppointment, uuidInvestigation]);

    return { appointment, loadingAppointment };
}