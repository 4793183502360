// Analytics/TabContent.jsx
import React from 'react';
import { Typography } from '@mui/material';
import { analyticsTabs } from './config/analyticsConfig';
import OutpatientsTab from './tabs/OutpatientsTab';
import PlaceholderChart from './PlaceholderChart';
import { IDepartment } from '../../../constants/types';
import InpatientsTab from './tabs/InpatientsTab';
import LabTab from './tabs/LabTab';
import RadiologyTab from './tabs/RadiologyTab';
import FinancesTab from './tabs/FinancesTab';

interface TabContentProps {
  tabNumber: number;
  loading: boolean;
  startDate: string;
  endDate: string;
  investigation: {
    permissions: string[];
    [key: string]: any;
  };
  departmentSelected: string;
  departments:IDepartment[];
}

const TabContent: React.FC<TabContentProps> = ({ 
  tabNumber, 
  loading, 
  startDate, 
  endDate, 
  investigation, 
  departmentSelected,
  departments
}) => {
  const currentTabData = analyticsTabs[tabNumber];

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  const commonProps = {
    investigation,
    departmentSelected,
    startDate,
    endDate,
    departments,
    tabNumber
  };

  console.log('commonProps', commonProps);

  // Render appropriate tab component based on current tab
  switch (currentTabData?.id) {
    case 'outpatients':
      return <OutpatientsTab {...commonProps} />;
    case 'inpatients':
      return <InpatientsTab {...commonProps} />;
    case 'laboratory':
      return <LabTab {...commonProps} />;
    case 'radiology':
      return <RadiologyTab {...commonProps} />;
    case 'finances':
      return <FinancesTab {...commonProps} />;
    default:
      console.log('No tab configuration found for:', tabNumber);
      return <Typography>No data available for this tab.</Typography>;
  }
};

export default TabContent;