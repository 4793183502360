import { makeStyles, styled, ThemeProvider } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import React, { useEffect, useMemo } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { formatDateByLocale } from '../../../utils/index.jsx';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

interface AppointmentDatePickerProps extends LocalizeContextProps {
    availableDaysWeek: string[];
    blockedDates: number[],
    slotsPerDay: number,
    autoCurrentDate?: boolean,
    selectBlockedDates?: boolean,
    initialDate?: Date,
    onDateChangeCallback: (date: Date) => void;
    datesOccupancy: { [date: string]: number }
}


const useStyles = makeStyles((theme) => ({
    dayPicker: {
        color: "#000000de",
        backgroundColor: "transparent",
        //backgroundColor: theme.palette.primary.main,
        fontWeight: 500,
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        tapHighlightColor: 'transparent',
        outline: 0,
        border: 0,
        padding: 0,
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        textDecoration: 'none',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: '0.75rem',
        lineHeight: 1.66,
        letterSpacing: '0.03333em',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        margin: '0 2px',
    },
    dayWithDotContainer: {
        position: 'relative',
    },
    dayWithDot: {
        position: 'absolute',
        height: 0,
        width: 0,
        border: '4px solid',
        borderRadius: 4,
        right: '50%',
        transform: 'translateX(4px)',
        top: '75%'
    },
    freeDay: {
        borderColor: 'green',
        backgroundColor: "green",
        "&:hover": {
            backgroundColor: "green",
        }
    },
    halfBusyDay: {
        borderColor: 'orange',
        backgroundColor: "orange",
        "&:hover": {
            backgroundColor: "orange",
        }
    },
    veryBusyDay: {
        borderColor: 'red',
        backgroundColor: "red",
        "&:hover": {
            backgroundColor: "red",
        }
    },
    fullDay: {
        borderColor: 'black',
        backgroundColor: "black",
        "&:hover": {
            backgroundColor: "black",
        }
    },
    daySelected: {
        color: "#ffffff",
        fontWeight: 500,
        width: '36px',
        height: '36px',
    },
    dayDisabled: {
        color: "grey",
        cursor: 'not-allowed',
    }
}))



const AppointmentDatePicker: React.FC<AppointmentDatePickerProps> = ({ availableDaysWeek, autoCurrentDate, blockedDates,
                                                                        slotsPerDay, activeLanguage, datesOccupancy,
                                                                        initialDate,
                                                                        selectBlockedDates, onDateChangeCallback, translate }) => {
    const nextAvailableDate = useMemo(() =>{
        return findNextAvailableDate();
    },[]);
    
    // Ensure date initialization is valid with proper fallbacks
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(() => {
        try {
            if (initialDate && dayjs(initialDate).isValid()) {
                return dayjs(initialDate).startOf('day');
            } else if (autoCurrentDate && nextAvailableDate && nextAvailableDate.isValid()) {
                return nextAvailableDate.startOf('day'); 
            }
            return null;
        } catch (error) {
            console.error("Error initializing date:", error);
            return null;
        }
    });
    const [dateError, setDateError] = React.useState<string | null>(null);
    const classes = useStyles();

    function occupancyLevel(date: Dayjs) {
        if(date){
            const momentObj = moment(date.toDate().getTime());
            const dateString = momentObj.format('YYYY-MM-DD');
            
            if (datesOccupancy.hasOwnProperty(dateString)) {
                if (slotsPerDay === datesOccupancy[dateString]) {
                    return classes.fullDay;
                }
                else {
                    const percent = datesOccupancy[dateString] / slotsPerDay;
                    return percent < 0.3 ? classes.freeDay : percent < 0.6 ? classes.halfBusyDay : classes.veryBusyDay;
                }
            }
            else {
                return classes.freeDay;
            }
        }
        
    }

    function isDisabledDate(date: Dayjs | Date, selectBlockedDates?: boolean) {
        const dateObj = date instanceof Date ? date : date.toDate();
        const currentWeekDay = dateObj.toLocaleDateString("en-En", { weekday: 'short' });
        const isAnOfficeDay = availableDaysWeek.includes(currentWeekDay);
        const isABlockDate = !selectBlockedDates && blockedDates.some((blockedTS) => {
            const blockedDate = new Date(blockedTS);
            return blockedDate.toDateString() === dateObj.toDateString();
        });
        return !(isAnOfficeDay || isABlockDate);
    }

    function onAcceptDate() {
        try {
            setDateError(null);
            if (!selectedDate) {
                setDateError(translate("pages.hospital.outpatients.no_date_selected").toString());
                return;
            }
            
            // Make sure the date is valid before proceeding
            if (!selectedDate.isValid()) {
                setDateError(translate("pages.hospital.outpatients.invalid_date").toString());
                return;
            }
            
            // Check if the date is disabled
            if (isDisabledDate(selectedDate, selectBlockedDates)) {
                setDateError(translate("pages.hospital.outpatients.date_not_available").toString());
                return;
            }
            
            onDateChangeCallback(selectedDate.toDate());
        } catch (error) {
            console.error("Error processing selected date:", error);
            setDateError(translate("pages.hospital.outpatients.date_error").toString());
        }
    }
    function findNextAvailableDate() {
        for (let i = 0; i < 60; i++) {
            const nextAvailableDate = dayjs().add(i, 'day');
            
            if (!isDisabledDate(nextAvailableDate.toDate(), selectBlockedDates)) {
                return nextAvailableDate;
            }
        }
        return dayjs();
    }
    useEffect(() => {
        if (autoCurrentDate && selectedDate) {
            onDateChangeCallback(selectedDate?.toDate());
            return;
        }
    }, [])

    const CustomDay = (props: PickersDayProps<Dayjs>) => {
        const { day, outsideCurrentMonth, onDaySelect, ...other } = props;
        const selected = day.toDate().getTime() === selectedDate?.toDate().getTime();
        const isToday = dayjs().startOf('day').isSame(day.startOf('day'));
        
        const occupancyClass = occupancyLevel(day);
        let classesString = classes.dayPicker;
        classesString = selected ? classesString + " " + classes.daySelected + " " + occupancyClass : classesString;
        classesString = isToday ? classesString + " MuiPickersDay-today" : classesString;
        
        const handleDayClick = (date: Dayjs) => {
            setSelectedDate(date);
            if (onDaySelect) {
                onDaySelect(date);
            }
        };

        if(isDisabledDate(day, selectBlockedDates) || outsideCurrentMonth){
            return (
                <button className={`${classes.dayPicker} ${classes.dayDisabled}`} type="button">
                    { day.format('DD') }
                </button>
            );
        }
        return (
            <button className={classesString} onClick={() => handleDayClick(day)} type="button">
                { day.format('DD') }
                <div className={`${classes.dayWithDot} ${occupancyClass}`}/>
            </button>
        );        
    }

    // Handle date changes safely
    const handleDateChange = (newDate: Dayjs | null) => {
        try {
            setDateError(null);
            // Validate the new date
            if (newDate && !newDate.isValid()) {
                setDateError(translate("pages.hospital.outpatients.invalid_date").toString());
                return;
            }
            setSelectedDate(newDate);
        } catch (error) {
            console.error("Error handling date change:", error);
            setDateError(translate("pages.hospital.outpatients.date_error").toString());
        }
    };

    return (
        <>
            <MobileDatePicker
                value={selectedDate}
                onChange={handleDateChange}
                onAccept={onAcceptDate}
                label={translate("pages.hospital.outpatients.select_date").toString()}
                shouldDisableDate={(date) => isDisabledDate(date, selectBlockedDates)}
                format={formatDateByLocale(activeLanguage.code)}
                slots={{
                    day: CustomDay as any,
                }}
                slotProps={{
                    day: {
                        selected: false,
                    } as any,
                }}
            />
        </>
    );
};

export default withLocalize(AppointmentDatePicker);
