import React, { useMemo } from 'react';
import { IDepartment } from '../../../constants/types';
import BarChart from './BarChart';


interface PatientsBarChartProps {
    departments:IDepartment[],
    departmentSelected:IDepartment,
    statsPerDepartment: StatsData,
    patients:any[],
    title:Element
}

interface PatientInvestigation {
    idPatientInvestigation: number;
    department: {
        id: number;
        uuid: string;
        code: string;
        name: string;
        type: number;
        createdAt: string;
        updatedAt: string;
        deletedAt: string | null;
    };
}

interface StatsData {
    [date: string]: PatientInvestigation[];
}
const PatientsBarChart: React.FC<PatientsBarChartProps> = ({ departments, title, patients, departmentSelected, statsPerDepartment }) => {



    const categories = useMemo(() => {
        return Object.keys(statsPerDepartment).sort((a, b) => new Date(a) - new Date(b) );
    }, [statsPerDepartment]);

    console.log('categories debug', categories) 
    console.log('patients debug', patients, departments, departmentSelected, statsPerDepartment, title);

    function seriesPerDepartment(currentDepartment:IDepartment){
        
        const seriesDepartment = categories.map((key) => {
            const countPatientsInDepartment = statsPerDepartment[key].filter((patient) => patient.department.uuid === currentDepartment.uuid).length;
            return countPatientsInDepartment;
        });
        return {
            name: currentDepartment.name,
            count: seriesDepartment
        };

    }
    const series = useMemo(() => {
        let tempSeries = [];
        if(!departments || !statsPerDepartment){
            return [];
        }
        if(!departmentSelected){
            for(let i = 0; i < departments.length; i++){
                const currentDepartment = departments[i];
                tempSeries.push(seriesPerDepartment(currentDepartment));
            }
            return tempSeries;
        }
        else{
            return [seriesPerDepartment(departmentSelected)];
        }
    }, [patients, statsPerDepartment]);
    
    if(categories && series){
        return(
            <BarChart title={title} categories={categories} series={series} />
        );
    }
    else{
        return null;
    }
};

export default PatientsBarChart;