export function generateRandomData(): { 
  series: { name: string; data: number[] }[];
  categories: string[];
  count: number;
} {
  const categories = [
    "Dermatology",
    "Médecine Interne",
    "Neurology",
    "Cardiology",
    "Orthopedics",
    "Pediatrics",
    "Oncology",
    "Radiology",
    "Psychiatry",
    "Endocrinology",
  ];

  // Shuffle and pick random categories
  const shuffledCategories = categories.sort(() => 0.5 - Math.random()).slice(0, 3);

  const data = Array.from({ length: shuffledCategories.length }, () => Math.floor(Math.random() * 20));

  return {
    series: [
      {
        name: "PlaceHolder Data",
        data,
      },
    ],
    categories: shuffledCategories,
    count: data.reduce((a, b) => a + b, 0),
  };
}