import { IRequest, RequestStatus, RequestType, ServiceType } from "./types";

export type LabStatus = RequestStatus.COMPLETED | RequestStatus.CANCELED | RequestStatus.PENDING_PAYMENT | RequestStatus.PAYMENT_COMPLETED;
export const labStatusValues: LabStatus[] = [
    RequestStatus.COMPLETED,
    RequestStatus.CANCELED,
    RequestStatus.PENDING_PAYMENT,
    RequestStatus.PAYMENT_COMPLETED
];

export type SurgeryStatus = RequestStatus.CHECKED_IN | RequestStatus.ANESTHESIOLOGIST_ACCEPTED | RequestStatus.COMPLETED | RequestStatus.PAYMENT_COMPLETED | RequestStatus.READY_FOR_SURGERY | RequestStatus.CANCELED | RequestStatus.PENDING_ANESTHESIOLOGIST | RequestStatus.ANESTHESIOLOGIST_REJECTED | RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS | RequestStatus.COMPLETED | RequestStatus.PENDING_PAYMENT | RequestStatus.ACCEPTED;
export const surgeryStatusValues: SurgeryStatus[] = [
    RequestStatus.ANESTHESIOLOGIST_ACCEPTED,
    RequestStatus.PAYMENT_COMPLETED,
    RequestStatus.READY_FOR_SURGERY,
    RequestStatus.COMPLETED,
    RequestStatus.PENDING_ANESTHESIOLOGIST,
    RequestStatus.PENDING_PAYMENT,
    RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS,
    RequestStatus.ANESTHESIOLOGIST_REJECTED,
    RequestStatus.CHECKED_IN,
    RequestStatus.CANCELED
];


export function getRequestStatusName(status: RequestStatus): string {
    return RequestStatus[status];
}


export function nextPosibleSurgeryStatus(request:SurgeryStatus):SurgeryStatus[]{
    switch(request){   
        case RequestStatus.PENDING_PAYMENT: 
            return [RequestStatus.PAYMENT_COMPLETED, RequestStatus.CANCELED]
        case RequestStatus.PAYMENT_COMPLETED:
            return [RequestStatus.PENDING_ANESTHESIOLOGIST, RequestStatus.CANCELED]
        case RequestStatus.PENDING_ANESTHESIOLOGIST: //Pending Anestesist approval
            return [RequestStatus.ANESTHESIOLOGIST_ACCEPTED, RequestStatus.ANESTHESIOLOGIST_REJECTED, RequestStatus.CANCELED];
        case RequestStatus.ANESTHESIOLOGIST_ACCEPTED:
            return [RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS, RequestStatus.READY_FOR_SURGERY, RequestStatus.CANCELED];      
        case RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS: //Pending buy protesis
            return [RequestStatus.READY_FOR_SURGERY, RequestStatus.CANCELED];
        case RequestStatus.READY_FOR_SURGERY:
            return [RequestStatus.CHECKED_IN, RequestStatus.CANCELED];
        case RequestStatus.CHECKED_IN:
            return [RequestStatus.COMPLETED, RequestStatus.CANCELED];
    }
    return [request];
}


/**
 * Calculates the total days for a request based on its type
 * @param request The request object
 * @returns The calculated number of days
 */
export const calculateTotalDays = (request: IRequest, requestServiceType:ServiceType): string => {

    if(request.completedAt){
        return "-";
    }
    let referenceDate = "createdAt";
        
    // Type-specific calculations
    switch (requestServiceType) {
        case ServiceType.SURGERY:
        referenceDate = "readyForSurgeryAt"
    }
    if(request[referenceDate as keyof IRequest]){
        const date = new Date(request[referenceDate as keyof IRequest]).getTime();
        const todayDate = new Date().getTime();
        const totalDays = Math.round((todayDate - date) / (1000 * 60 * 60 * 24));
        return totalDays.toString();
    }
    return "-";
}; 