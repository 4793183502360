import React, { useMemo} from 'react';
import { Grid, Card, CardHeader, CardContent, Box } from '@mui/material';
import { useOutpatientsData } from '../../hooks/useOutpatientsData';
import { analyticsCharts } from '../../config/analyticsCharts';
import LoadingState from '../../common/LoadingState';
import ErrorState from '../../common/ErrorState';
import ChartFactory from '../../charts/ChartFactory';
import { IDepartment } from '../../../../../constants/types';

interface ChartConfig {
  id: string;
  source: string;
  order: number;
  title: string;
  type: 'bar' | 'line' | 'pie' | 'doughnut' | 'gauge' | 'demographic' | 'data-only' | 'data-value';
  cols: number;
  visible: boolean;
  debug?: boolean;
}

interface OutpatientsTabProps {
  investigation: {
    permissions: string[];
    [key: string]: any;
  };
  departmentSelected: string;
  startDate: number | null;
  endDate: number | null;
  departments: IDepartment[];
}

const OutpatientsTab: React.FC<OutpatientsTabProps> = ({ 
  investigation, 
  departmentSelected, 
  startDate, 
  endDate, 
  departments,
}) => {
  const { data, loading, error } = useOutpatientsData(
    investigation, 
    departmentSelected, 
    startDate, 
    endDate
  );

  const visibleCharts = useMemo(() => 
    (analyticsCharts.outpatients as ChartConfig[] || [])
      .filter(chart => chart.visible)
      .sort((a, b) => a.order - b.order),
    [analyticsCharts.outpatients]  // Add dependency
  );
  
  const debugCharts = useMemo(() => 
    (analyticsCharts.outpatients as ChartConfig[] || [])
      .filter(chart => chart.debug)
      .sort((a, b) => a.order - b.order),
    [analyticsCharts.outpatients]  // Add dependency
  );

  const defaultDepartment = useMemo(() => 
    departments && departments.length > 0 ? departments[0] : null,
    [departments]
  );

  if (loading) return <LoadingState />;
  if (error) return <ErrorState error={error} />;


  return (
    <>
    <Grid container spacing={3}>
      {visibleCharts.map((chart) => (
        <Grid 
          item 
          xs={12} 
          sm={chart.cols === 12 ? 12 : 6} 
          md={chart.cols} 
          key={chart.id}
        >
          <ChartFactory
            id={chart.id}
            type={chart.type}
            title={chart.title}
            data={data ? data[chart.source] : null}
            departments={departments}
            departmentSelected={defaultDepartment} 
          />
        </Grid>
      ))} 
    </Grid>
      <>
      {debugCharts.map((chart) => (
        <Card>
        <CardHeader title={chart.id} subheader={chart.source} />
        <CardContent>
          <Box >
            {/* <Skeleton variant="rectangular" height="100%" animation="wave" /> */}
            {data && (
              <pre style={{ 
                overflow: 'auto', 
                maxHeight: '100%',
                margin: 0,
                padding: '1rem',
                background: '#f5f5f5',
                borderRadius: '4px'
              }}>
                {JSON.stringify(data[chart.source], null, 2)}
              </pre>
            )}
          </Box>
        </CardContent>
      </Card>
            ))}
      </>

    </>
  );
};

export default OutpatientsTab;