// analytics/hooks/useInpatientsData.ts
import { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';  // Import useSelector instead of connect

import { inpatientsServices } from '../services/endpoints/inpatients';
import { IPersonalData, ReduxStore, IPatient, IDepartment } from '../../../../constants/types';
import { DistributionResponse, DischargesResponse, ChartData } from '../types';
import { convertDischargeStatsToChartData } from '../services/transformers';
import { updatePatientsFromId } from '../../../../redux/actions/patientsActions';


interface InpatientsData {
	totalAdmissions: ChartData;
	ageGroup: DistributionResponse;
	totalDischarges: ChartData;
	dischargeRate: DistributionResponse;
	avgStayLength: ChartData;
	bedOccupancy: DistributionResponse;
	readmissionRate: DistributionResponse;
	mortalityRate: DischargesResponse;                         
	pharmacyUsage: DistributionResponse;
	admissionsByDepartment: DistributionResponse;
	indicatorsByDepartment: DistributionResponse;
	diagnosis: DistributionResponse;
}

export const inpatientsMethodMap = {
	totalAdmissions: inpatientsServices.getTotalAdmissions,
	ageGroup: inpatientsServices.getAgeGroup,
	totalDischarges: inpatientsServices.getTotalDischarges,
	dischargeRate: inpatientsServices.getDischargeRate,
	avgStayLength: inpatientsServices.getAvgStayLength,
	bedOccupancy: inpatientsServices.getBedOccupancy,
	readmissionRate: inpatientsServices.getReadmissionRate,
	mortalityRate: inpatientsServices.getMortalityRate,
	pharmacyUsage: inpatientsServices.getPharmacyUsage,
	admissionsByDepartment: inpatientsServices.getAdmissionsByDepartment,
	indicatorsByDepartment: inpatientsServices.getIndicatorsByDepartment,
	diagnosis: inpatientsServices.getDiagnosis,
};

interface UseInpatientsDataOptions {
	investigation: { uuid: string };
	departmentSelected?: string;
	startDate: string;
	endDate: string;
	requestedMethod: keyof typeof inpatientsMethodMap;
}

export const useInpatientsData = ({
	investigation,
	departmentSelected,
	startDate,
	endDate,
	requestedMethod,
}: UseInpatientsDataOptions) => {
	const [data, setData] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);

	// Get patients data from Redux store using useSelector
	const investigationsData = useSelector((state: ReduxStore) => {
		const investigations = state.hospital.data.departments;
		if (!investigations) return [];
		return investigations as IDepartment[];
	}, shallowEqual); // Add shallowEqual from react-redux

	const patientsData = useSelector((state: ReduxStore) => {
		const currentInvestigation = state.investigations.currentInvestigation;
		if (!currentInvestigation) return [];
		return state.patients.data?.[currentInvestigation.uuid] as IPatient[] || [];
	}, shallowEqual);

	const dispatch = useDispatch();

	useEffect(() => {
		const abortController = new AbortController();
		if (!patientsData.length) {
			dispatch(updatePatientsFromId(investigation, 0));
		}

		const uuidDepartment = departmentSelected || 'all';

		if (!investigation?.uuid || !startDate || !endDate) {
			setLoading(false);
			return;
		}

		const fetchData = async () => {
			setLoading(true);
			setError(null);

			try {
				const fetchMethod = inpatientsMethodMap[requestedMethod];
				if (!fetchMethod) {
					throw new Error(`Unknown method: ${requestedMethod}`);
				}

				const response = await fetchMethod(
					investigation.uuid,
					uuidDepartment,
					startDate,
					endDate,
					abortController.signal
				);

				// Special handling for totalDischarges
				if (requestedMethod === 'totalDischarges') {
					setData(convertDischargeStatsToChartData(response.stats));
				} else {
					// For other methods, use the response directly
					setData(response);
				}
			} catch (err) {
				console.error('Error fetching inpatients data:', err);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
		return () => abortController.abort();
	}, [investigation, departmentSelected, startDate, endDate, patientsData, requestedMethod]);

	return { data, loading, error };
};
