// @ts-nocheck
// Removed unused imports: useEffect, useMemo, Button, Card, CardContent, IconButton, Paper, Snackbar, Typography, Translate, 
// DoughnutChart, postErrorSlack, yearsFromDate, TimeTable, SearchTable, HospitalStats, PatientsBarChart, 
// OutpatientsStats, CommonDiagnosis, BillingChart, BillingInsuranceBars, SurveyResearcherStats, FUNCTIONALITY, 
// and also removed styled(MuiDivider) + Divider import from "@mui/material", since it was not used.
// Removed unused analyticsTabs from config.
// Removed unused variables: departmentSelected (never used), statsFirstMonitoring, loadingStatsFirstMonitoring, 
// mostCommonDiagnosis, appointmentsPerDepartment, billingDepartments, billingInsurances (they were declared 
// but not referenced in the component).

// Analytics/index.tsx

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { green, red, orange, yellow, blue, amber, brown, cyan, deepOrange } from "@mui/material/colors";
import { Box, Grid } from '@mui/material';

import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import { withLocalize } from 'react-localize-redux';
import { ROUTE_401 } from '../../../routes/urls';
import styled, { withTheme } from "styled-components";
import { spacing } from "@mui/system";
import DatesSelector from '../../dashboards/Analytics/DatesSelector';

import { useDeparmentsSelector, useInsurances, usePatients } from '../../../hooks';
import { PERMISSION } from '../../../components/investigation/share/user_roles';
import SectionHeader from '../../components/SectionHeader';
import AnalyticsHeader from './AnalyticsHeader';
import TabContent from './TabContent';

import usePatientFilter from './hooks/usePatientFilter';
import { LIST_COLORS, ageGroups, COUNT_AGE } from './config/constants';


export function Analytics(props) {
  const { patients } = usePatients(props.uuidInvestigation);
  const history = useHistory();
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const [activeTab, setActiveTab] = useState(0);
  // This hook is used, but we aren’t referencing the insurances data in the UI yet.
  const [insurances] = useInsurances();

  const [activityPatients, setActivityPatients] = useState([]);
  const [countSex, setCountSex] = useState({ male: 0, female: 0 });
  
  // Keep age-based state if it’s relevant for future logic
  const [countAge, setCountAge] = useState([...COUNT_AGE]);

  // Only used to conditionally render department selectors
  const onlyDepartmentsResearcher =
    props.investigations.currentInvestigation &&
    props.investigations.currentInvestigation.permissions.includes(
      PERMISSION.ANALYTICS_DEPARTMENT
    );

  const {
    renderDepartmentSelector,
    departments,
    departmentSelected,
  } = useDeparmentsSelector(true, onlyDepartmentsResearcher, true, "all");

  // Use custom hook to filter patients based on department selection
  const { loadingPatientsInfo, filteredPatients } = usePatientFilter(
    props.investigations.currentInvestigation,
    departmentSelected,
    startDate,
    endDate,
  );

  console.log("departments", departments, departmentSelected, startDate, endDate, filteredPatients);
  // For controlling which tab is active on AnalyticsHeader

  function datesSelected(dates) {
    console.log("We have dates", dates);
    setStartDate(dates[0].getTime());
    setEndDate(dates[1].getTime());
  }

  function renderSelectors() {
    if (departments === null) {
      return (
        <Grid item xs={12}>
          <Loader />
        </Grid>
      );
    }

    if (departments.length === 1) {
      return (
        <Grid item xs={12}>
          <DatesSelector onCallBack={datesSelected} />
        </Grid>
      );
    }

    return (
      <Box width="100%" sx={{ padding: 2 }}>
        <Box minWidth="100%" display={{ xs: 'flex', sm: 'flex' }} flexDirection={{ xs: 'column', md: 'row' }}  gap={2}>
          {/* Department Selector */}
          <Box minWidth={{ xs: '100%', md: '200px' }} >
            {renderDepartmentSelector()}
          </Box>
    
          {/* Date Selector with fixed layout */}
          <Box minWidth={{ xs: '100%', md: 'auto' }}  display="flex" alignItems="top">
            <DatesSelector onCallBack={datesSelected} />
          </Box>
        </Box>
      </Box>
    );
    
  }

  // If the current investigation is still loading or not available
  if (props.investigations.loading || !props.investigations.currentInvestigation) {
    return <Loader />;
  }
  // If the user lacks the necessary permissions
  else if (
    props.investigations.currentInvestigation &&
    !props.investigations.currentInvestigation.permissions.filter((perm) =>
      [PERMISSION.BUSINESS_READ, PERMISSION.ANALYTICS_DEPARTMENT].includes(perm)
    ).length > 0
  ) {
    history.push(ROUTE_401);
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />

      <Grid container spacing={6}>
        <Grid item xs={12} style={{ color: "white" }}>
          <SectionHeader section="analytics" />
        </Grid>

        <Grid item xs={12}>
          <AnalyticsHeader
            colors={LIST_COLORS}
            onTabChange={(index) => {
              setActiveTab(index);
              // Additional logic for handling tab changes can go here
            }}
          />
        </Grid>

        <Grid item xs={12} spacing={3} style={{ background: 'white', padding: '2rem' }}>
          <Grid container justifyContent="between" spacing={3}>
            {renderSelectors()}
          </Grid>
        </Grid>
      </Grid>

      {/* TabContent handles whichever tab is active */}
      <TabContent  tabNumber={activeTab} loading={props.investigations.loading || !props.investigations.currentInvestigation} startDate={startDate} endDate={endDate} investigation={props.investigations.currentInvestigation} departmentSelected={departmentSelected} departments={departments} />
    </React.Fragment>
  );
}

Analytics.propTypes = {
  investigations: PropTypes.object
};

const mapStateToProps = (state) => ({
  investigations: state.investigations
});

export default withTheme(withLocalize(connect(mapStateToProps, null)(Analytics)));
