import React from 'react';
import { Card, CardHeader, CardContent, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';

export interface Trend {
  type: "days" | "weeks" | "months" | "default";
  data: number[];
  totals: number[];
}

interface ChartProps {
  data: {
    trend: Trend;
    stats: {
      name: string;
      totalPatients: number;
    }[];
  };
  title: string;
  type?: string;
  height?: number;
}

const TrendBarChart: React.FC<ChartProps> = ({ 
  data, 
  title, 
  type = 'bar',
  height = 300 
}) => {
  const getLabels = () => {
    switch (data.trend.type) {
      case 'days':
        return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      case 'weeks':
        return ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'];
      case 'months':
        return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
      default:
        return data.stats.map(stat => stat.name);
    }
  };

  const chartData = {
    labels: getLabels(),
    datasets: [
      {
        label: 'Trend',
        data: data.trend.data,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Totals',
        data: data.trend.totals,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Card>
      <CardHeader 
        title={title} 
        subheader={`Showing ${data.trend.type} trend`} 
      />
      <CardContent>
        <Box height={height}>
          <Bar 
            data={chartData} 
            options={options}
            height={height}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TrendBarChart;